<dialog-header
  [title]="data?.title"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  style="
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <form
    [formGroup]="form"
    (ngSubmit)="primaryTapped()"
    style="margin-top: 20px"
  >
    <div class="form-field" style="margin-top: 20px" *ngIf="!this.data.stamps">
      <div class="form-field-label">{{ data?.label }}</div>
      <input
        class="form-input"
        autocomplete="off"
        [maxlength]="5"
        [formControl]="pointsFormControl"
        type="number"
        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      />
    </div>

    <div class="form-field" style="margin-top: 20px" *ngIf="this.data.stamps">
      <single-select
        [label]="data.label"
        [formCtrl]="stampsFormControl"
        [property]="data.property"
        [options]="options"
        [showIcon]="false"
        [infoText]="data?.infoText"
        [value]="stampsFormControl.value"
      ></single-select>
    </div>

    <div
      class="form-field"
      style="margin-top: 20px"
      *ngIf="locations.length > 1"
    >
      <single-select
        label="Location"
        [formCtrl]="locationFormControl"
        [options]="locations"
        infoText="Select a location to attribute this to for reporting purposes"
        [property]="'name'"
        [value]="locationFormControl.value"
      ></single-select>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <app-secondary-button
    style="margin-right: 10px"
    [title]="'Cancel'"
    [disabled]="false"
    (tapped)="matDialogRef.close()"
  ></app-secondary-button>

  <app-primary-button
    [title]="'Save'"
    [disabled]="false"
    (tapped)="primaryTapped()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>

<loader [loading]="loading || locationsLoading"></loader>
