<page-header
  [title]="constants.strings.nfcCards"
  [subtitle]="subtitle"
></page-header>

<div style="display: flex; justify-content: flex-end; margin-bottom: 10px">
  <app-primary-button
    [title]="constants.strings.newNFCCard"
    [disabled]="false"
    (tapped)="openRequestDialog()"
    [icon]="'add'"
  ></app-primary-button>
</div>

<div *ngIf="!cardsLoading">
  <ngx-datatable
    #myTable
    class="material"
    [headerHeight]="45"
    [rows]="nfcCards"
    [scrollbarV]="false"
    [messages]="{ emptyMessage: emptyString }"
    [scrollbarH]="true"
    [footerHeight]="50"
    [columnMode]="'force'"
    [rowHeight]="48"
    [externalPaging]="true"
    [count]="nfcCards.length"
    (scroll)="handleScroll()"
    (activate)="rowTappedNGX($event)"
  >
    <ngx-datatable-column name="Created" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.createdAt | date : constants.longDate }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Card #" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.ref }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Nickname" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.nickname ?? "-" }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Location"
      [resizeable]="false"
      *ngIf="locationCount > 1"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.location?.name ?? "Not linked" }}
      </ng-template>
    </ngx-datatable-column>

    <!-- <ngx-datatable-column name="Type" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.asset ?? "card" | titlecase }}
      </ng-template>
    </ngx-datatable-column> -->

    <!-- <ngx-datatable-column name="Last Used" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.updatedAt | date : constants.longestDate }}
      </ng-template>
    </ngx-datatable-column> -->

    <ngx-datatable-column name="Active" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="custom-slider" (click)="$event.stopPropagation()">
          <mat-slide-toggle
            [disabled]="!merchantActive"
            [checked]="row?.active"
            (change)="toggleCard(row)"
          ></mat-slide-toggle>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <mat-icon
          class="status-icon"
          style="color: var(--app-timber)"
          (click)="$event.stopPropagation()"
          [mat-menu-trigger-for]="tableMenu"
          [matMenuTriggerData]="{ row: row }"
          >more_horiz</mat-icon
        >
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-offset="offset"
        let-isVisible="isVisible"
      >
        <div class="page-count" *ngIf="nfcCards.length > 0">
          <span>{{ nfcCards.length | pluralize : "card" : "cards" }}</span>
        </div>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>

<loader [loading]="loading"></loader>

<mat-menu #tableMenu="matMenu" class="custom-menu">
  <ng-template matMenuContent let-row="row">
    <div *ngFor="let item of tableMenuOptions; let i = index">
      <button
        [disabled]="false"
        class="menu-list-item"
        style="border-radius: 5px"
        (click)="onClick(item.title, row)"
        mat-menu-item
      >
        <mat-icon
          fontSet="material-icons-outlined"
          style="
            font-size: 16px;
            vertical-align: middle;
            display: flex;
            align-items: center;
            margin-right: 5px;
          "
          >{{ item.icon }}</mat-icon
        >
        <span class="menu-list-item-label"> {{ item.title }}</span>
      </button>
    </div>
  </ng-template>
</mat-menu>
