<dialog-header
  [title]="data.title"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div mat-dialog-content>
  <ngx-datatable
    #myTable
    class="material"
    [headerHeight]="45"
    [rows]="invites"
    [scrollbarV]="false"
    [messages]="{ emptyMessage: emptyString }"
    [scrollbarH]="true"
    [footerHeight]="50"
    [columnMode]="'force'"
    [rowHeight]="48"
    [externalPaging]="true"
    [count]="length"
    [offset]="pageIndex"
    (page)="pageEvent($event)"
    (scroll)="handleScroll()"
    [limit]="pageSize"
  >
    <ngx-datatable-column name="Date" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.createdAt | date : constants.longDate }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Email" [resizeable]="true">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row?.email }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Signed up" [resizeable]="true">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <mat-icon
          *ngIf="row?.signUpDate; else noSignUp"
          style="color: green; font-size: 18px; vertical-align: middle"
        >
          check_circle
        </mat-icon>
        <ng-template #noSignUp>
          <mat-icon style="color: red; font-size: 18px; vertical-align: middle">
            cancel
          </mat-icon>
        </ng-template>
        {{
          row?.signUpDate
            ? (row?.signUpDate | date : constants.longDate)
            : "Not signed up"
        }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-offset="offset"
        let-isVisible="isVisible"
      >
        <div class="page-count" *ngIf="length > 0">
          <span>{{ range }}</span>
        </div>
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'"
          [page]="pageIndex + 1"
          [size]="pageSize"
          [count]="length"
          [hidden]="!(rowCount / pageSize > 1)"
          (change)="table.onFooterPage($event)"
        >
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
</div>

<loader [loading]="loading"></loader>
