import { Component, Input } from '@angular/core';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Constants } from 'src/app/app.constants';

import {
  AuthService,
  CardService,
  LocationService,
  MerchantService,
} from '../../../services/index';
import { Location, StampCard, Shapes, CardType } from '../../../models/index';

import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { Options } from '@angular-slider/ngx-slider';
import { RewardDialogComponent } from '../reward-dialog/reward-dialog.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { IconDialogComponent } from '../icon-dialog/icon-dialog.component';
import { GradientDialogComponent } from '../gradient-dialog/gradient-dialog.component';
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';
import { StorageService } from 'src/app/services/storage.service';
import { SendAs } from 'src/app/models/message';
import { Merchant } from 'src/app/models/merchant';
import { File } from 'src/app/models/file';
import { StampsPerScanComponent } from './stamps-per-scan/stamps-per-scan.component';

@Component({
  selector: 'card-detail',
  templateUrl: './card-detail.component.html',
  styleUrls: ['./card-detail.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ background: 'red' }),
        animate('1s ease-out', style({})),
      ]),
    ]),
  ],
})
export class CardDetailComponent {
  options = [
    {
      value: this.constants.assets.tag,
      label: 'NFC Tags only',
    },
    {
      value: this.constants.assets.card,
      label: 'NFC Cards only',
    },
    {
      value: this.constants.assets.both,
      label: 'NFC Tags & Cards',
    },
  ];
  selectedDailyOption: string = this.constants.assets.both;
  selectedIntervalOption: string = this.constants.assets.both;

  @Input() smallTitle = false;
  @Input() title = '';
  @Input() subtitle: string = '';

  tabs = ['overview', 'theme', 'stamps', 'rewards', 'restrictions'];
  selectedTab = 'overview';
  selectedIndex = 0;

  interval: boolean = false;
  stampsPerDay: boolean = false;
  showLogo: boolean = true;
  showStamps: boolean = false;
  soundOn: boolean = true;
  stampIcon?: { mobile: string; web: string; filled: boolean } = {
    mobile: '0xf3e0',
    web: 'star',
    filled: false,
  };
  rewardIcon?: { mobile: string; web: string; filled: boolean } = {
    mobile: '0xf00e6',
    web: 'redeem',
    filled: false,
  };

  rewardIcons?: {
    stamp?: number;
    mobile?: string;
    web?: string;
    filled?: boolean;
    image?: any;
  }[] = [];

  stampImage?: { name: string; content: ArrayBuffer };
  rewardImage?: { name: string; content: ArrayBuffer };
  fromOptions: SendAs[] = [];
  cardGradient?:
    | {
        from: string;
        to: string;
        angle: number;
        value: number;
      }
    | undefined;

  colorOptions = ['Solid', 'Gradient'];

  submitted = false;

  totalInitialStamps = 12;

  totalStampsOptions: any = [];
  joinStampsOptions: any = [];
  stampsPerScanOptions: any = [];
  cardTypeOptions: { label: string; value: string; info: string }[] = [
    {
      label: 'Stamps',
      value: 'stamp',
      info: 'Customer gets rewards after obtaining a certain number of stamps',
    },
    {
      label: 'Points (fixed)',
      value: 'point',
      info: 'Customer gets rewards after obtaining a certain number of points',
    },
    {
      label: 'Points (open ended)',
      value: 'openEndedPoint',
      info: 'Customer accumulates points, which can be converted into rewards at any time',
    },
  ];

  opacitySliderValue: number = 10;
  opacitySliderOptions: Options = {
    floor: 1,
    ceil: 10,
    disabled: false,
  };

  tags: { name: string }[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  form!: FormGroup;
  titleFormControl: FormControl = new FormControl();
  descriptionFormControl: FormControl = new FormControl();
  fromFormControl: FormControl = new FormControl();
  locationFormControl: FormControl = new FormControl();
  cardColorFormControl: FormControl = new FormControl();
  textColorFormControl: FormControl = new FormControl();
  stampColorFormControl: FormControl = new FormControl();
  stampFillColorFormControl: FormControl = new FormControl();
  stampFillEmptyFormControl: FormControl = new FormControl();
  iconColorFormControl: FormControl = new FormControl();
  totalStampsFormControl: FormControl = new FormControl();
  joinStampsFormControl: FormControl = new FormControl();
  stampsPerScanStampsFormControl: FormControl = new FormControl();
  stampsPerDayStampsFormControl: FormControl = new FormControl();
  intervalHoursFormControl: FormControl = new FormControl();
  intervalMinutesFormControl: FormControl = new FormControl();
  cardTypeFormControl: FormControl = new FormControl();

  totalPointsFormControl: FormControl = new FormControl();
  maxPointsFormControl: FormControl = new FormControl();
  joinPointsFormControl: FormControl = new FormControl();
  pointsPerScanFormControl: FormControl = new FormControl();
  pointsExpiryFormControl: FormControl = new FormControl();
  progressBarFormControl: FormControl = new FormControl();
  progressBarBgFormControl: FormControl = new FormControl();

  saving = false;

  editMode: boolean = false;
  cardLoading: boolean = false;
  locationsLoading: boolean = false;
  planLimitsLoading: boolean = false;
  error: boolean = false;
  card: StampCard = {};
  rewards: any[] = [];
  locations: Location[] = [];

  shape: Shapes = Shapes.square;

  selectedShapeBorderColor = '#344734';
  merchantLogo?: string | undefined;
  customLogo?: File | undefined;
  showCustomLogo = false;
  planLimits: any;

  showCustomBrandingOption = false;
  disabledLocations: boolean[] = [];

  pointsExpire = false;
  canEditCard = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public constants: Constants,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private cardService: CardService,
    private authService: AuthService,
    private locationService: LocationService,
    private merchantService: MerchantService,
    private mixpanel: AnalyticsAbstract,
    private storageService: StorageService
  ) {
    this.merchantLogo = this.authService.getMerchantLogo();

    this.route.params.subscribe((params) => (this.card.nanoid = params['id']));

    this.route.queryParams.subscribe((params) => {
      const tab = params['tab'];

      if (tab) {
        this.selectedTab = tab;
        this.selectedIndex = this.tabs.findIndex((el) => el === tab);
      }
    });

    if (this.card.nanoid) {
      this.editMode = true;
    }

    this.titleFormControl = new FormControl(
      { value: '', disabled: false },
      Validators.required
    );

    this.descriptionFormControl = new FormControl(
      { value: '', disabled: false },
      Validators.required
    );

    this.fromFormControl = new FormControl(
      { value: '', disabled: false },
      Validators.required
    );

    this.locationFormControl = new FormControl(
      { value: '', disabled: false },
      Validators.required
    );

    this.cardColorFormControl = new FormControl({
      value: '#e7d1b5',
      disabled: false,
    });

    this.stampColorFormControl = new FormControl({
      value: '#e7d1b5',
      disabled: false,
    });

    this.stampFillColorFormControl = new FormControl({
      value: '#344734',
      disabled: false,
    });

    this.stampFillEmptyFormControl = new FormControl({
      value: '#f2f2f2',
      disabled: false,
    });

    this.iconColorFormControl = new FormControl({
      value: '#ffffff',
      disabled: false,
    });

    this.textColorFormControl = new FormControl({
      value: '#000000',
      disabled: false,
    });

    this.totalStampsFormControl = new FormControl({
      value: 12,
      disabled: false,
    });

    this.joinStampsFormControl = new FormControl({
      value: 0,
      disabled: false,
    });

    this.stampsPerScanStampsFormControl = new FormControl({
      value: 1,
      disabled: false,
    });

    this.stampsPerDayStampsFormControl = new FormControl({
      value: 1,
      disabled: false,
    });

    this.intervalHoursFormControl = new FormControl({
      value: 0,
      disabled: false,
    });

    this.intervalMinutesFormControl = new FormControl({
      value: 30,
      disabled: false,
    });

    this.cardTypeFormControl = new FormControl(
      {
        value: '',
        disabled: false,
      },
      [Validators.required]
    );

    this.progressBarFormControl = new FormControl({
      value: '#344734',
      disabled: false,
    });

    this.progressBarBgFormControl = new FormControl({
      value: '#e4e8eb',
      disabled: false,
    });

    this.form = this.fb.group({
      title: this.titleFormControl,
      description: this.descriptionFormControl,
      locations: this.locationFormControl,
      stampColor: this.stampColorFormControl,
      stampFillColor: this.stampFillColorFormControl,
      stampFillEmptyColor: this.stampFillColorFormControl,
      iconColor: this.iconColorFormControl,
      cardColor: this.cardColorFormControl,
      textColor: this.textColorFormControl,
      totalStamps: this.totalStampsFormControl,
      joinStamps: this.joinStampsFormControl,
      stampsPerScan: this.stampsPerScanStampsFormControl,
      stampsPerDay: this.stampsPerDayStampsFormControl,
      intervalHours: this.intervalHoursFormControl,
      intervalMinutes: this.intervalMinutesFormControl,
      progressBarColor: this.progressBarFormControl,
      progressBarBgColor: this.progressBarBgFormControl,
      totalPoints: this.totalPointsFormControl,
      joinPoints: this.joinPointsFormControl,
      pointsPerScan: this.pointsPerScanFormControl,
    });

    this.cardColorFormControl.valueChanges.subscribe((value) => {
      if (value) {
        this.cardGradient = undefined;
      }
    });
  }

  ngOnInit() {
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');

    if (!this.editMode) {
      this.getPlanLimits();
      this.getLocations();

      if (typeof this.authService.getUserProperty('merchant') != 'string') {
        const merchant = this.authService.getUserProperty(
          'merchant'
        ) as Merchant;
        if (merchant) {
          this.setCustomBrandingOption(merchant);
        }
      }
    } else {
      this.getCard();
    }

    this.totalStampsOptions = Array.from(
      { length: this.totalInitialStamps },
      (_, i) => i + 1
    );

    this.updateStampOptions(this.totalInitialStamps);
  }

  get canCreateCard(): boolean {
    if (this.editMode) {
      if (this.locations?.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.planLimits.cards > 0 && this.locations.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  getPlanLimits(): void {
    this.planLimitsLoading = true;
    this.merchantService
      .getPlanLimits()
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.planLimits = res;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => {
        this.planLimitsLoading = false;
      });
  }

  tabChanged(index: any): void {
    let tab = this.tabs[index];

    this.addQueryParams({ tab: tab });

    this.selectedTab = tab;

    this.mixpanel.track(Constants.analytics_keys.selectCardDetailTab, {
      Tab: tab,
    });
  }

  addQueryParams(params: any) {
    this.route.queryParams.subscribe((queryParams) => {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: params,
        queryParamsHandling: 'merge',
        skipLocationChange: false,
      });
    });
  }

  back() {
    this.router.navigate([Constants.routes.cards]);
  }

  cardTypeSelected(): void {
    this.setValidators();
  }

  setValidators(): void {
    if (this.cardTypeFormControl.value.value === CardType.point) {
      this.totalPointsFormControl.setValidators([Validators.required]);
      this.joinPointsFormControl.setValidators([Validators.required]);
      this.pointsPerScanFormControl.setValidators([Validators.required]);

      this.totalStampsFormControl.clearValidators();
      this.joinStampsFormControl.clearValidators();
      this.stampsPerScanStampsFormControl.clearValidators();
    } else {
      this.totalStampsFormControl.setValidators([Validators.required]);
      this.joinStampsFormControl.setValidators([Validators.required]);
      this.stampsPerScanStampsFormControl.setValidators([Validators.required]);

      this.totalPointsFormControl.clearValidators();
      this.joinPointsFormControl.clearValidators();
      this.pointsPerScanFormControl.clearValidators();
    }

    this.totalStampsFormControl.updateValueAndValidity();
    this.joinStampsFormControl.updateValueAndValidity();
    this.stampsPerScanStampsFormControl.updateValueAndValidity();

    this.totalPointsFormControl.updateValueAndValidity();
    this.joinPointsFormControl.updateValueAndValidity();
    this.pointsPerScanFormControl.updateValueAndValidity();
  }

  updateStampOptions(length: number) {
    this.joinStampsOptions = Array.from({ length: length + 1 }, (_, i) => i);
    this.stampsPerScanOptions = Array.from({ length: length }, (_, i) => i + 1);

    if (length < this.joinStampsFormControl.value) {
      this.joinStampsFormControl.setValue(0);
    }

    if (length < this.stampsPerScanStampsFormControl.value) {
      this.stampsPerScanStampsFormControl.setValue(1);
    }

    this.rewards.forEach((reward) => {
      const stamp = reward.stamp;

      if (stamp > length) {
        reward.stamp = length;
      }
    });

    this.sortRewards();
  }

  sortRewards(): void {
    this.rewards = this.rewards.sort((a, b) => {
      if (this.isStampCard) {
        return a.stamp - b.stamp;
      } else {
        return a.points - b.points;
      }
    });
  }

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result: BreakpointState) => {
        return result.matches;
      })
    );

  getLocations(): void {
    this.locationsLoading = true;

    const subscription =
      this.authService.getUserProperty('userType') ===
      this.constants.userTypes.manager
        ? this.merchantService.getStaffLocations()
        : this.locationService.getLocations(1000, 0);

    subscription
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.locations = res?.docs ?? res;
            this.locations?.sort((a, b) => a.name!.localeCompare(b.name!));

            if (this.editMode) {
              this.locationFormControl.setValue(this.card.locations);

              for (var location of this.card.locations ?? []) {
                if (!this.locations.some((loc) => loc._id == location._id)) {
                  this.disabledLocations.push(true);
                }
              }
            } else {
              this.locationFormControl.setValue(this.locations);
            }

            const merchantName = this.authService.getNestedUserProperty(
              'merchant',
              'name'
            );

            if (merchantName) {
              this.fromOptions.push({
                name: merchantName,
                type: 'merchant',
              });
            }

            if (this.locations?.length) {
              this.locations.forEach((el: Location) => {
                this.fromOptions.push({
                  name: el.name!,
                  type: 'location',
                });
              });
            }

            if (this.card.displayAs) {
              this.fromFormControl.setValue(
                this.fromOptions.find(
                  (el) =>
                    el.name == this.card.displayAs?.name &&
                    el.type == this.card.displayAs?.type
                )
              );
            } else {
              this.fromFormControl.setValue(this.fromOptions[0]);
            }

            if (this.fromOptions?.length === 1) {
              this.fromFormControl.disable();
            }

            this.setCanEditCard();
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => {
        this.locationsLoading = false;
      });
  }

  getCard(): void {
    this.cardLoading = true;
    this.cardService
      .getCard(this.card.nanoid!)
      .subscribe({
        next: (res: StampCard) => {
          if (res) {
            this.card = res;
            this.titleFormControl.setValue(res.title);
            this.descriptionFormControl.setValue(res.description);
            this.totalStampsFormControl.setValue(res.numberOfStamps ?? 9);
            this.joinStampsFormControl.setValue(res.joinStamps ?? 0);
            this.stampsPerScanStampsFormControl.setValue(
              res.stampsPerScan ?? 1
            );
            this.cardColorFormControl.setValue(res.colors?.card);
            this.textColorFormControl.setValue(res.colors?.text);
            this.stampColorFormControl.setValue(res.colors?.stampOutline);
            this.stampFillColorFormControl.setValue(res.colors?.stampFill);
            this.stampFillEmptyFormControl.setValue(res.colors?.stampFillEmpty);
            this.iconColorFormControl.setValue(res.colors?.icons);
            this.opacitySliderValue = res.icons?.opacity!;
            // this.bgImageOpacityValue = res.bgImageOpacity ?? 10;
            this.rewards = res.rewards ?? [];
            this.sortRewards();

            this.showLogo = res.showLogo ?? true;
            this.soundOn = res.soundOn ?? true;
            this.shape = this.getShapeFromString(res.shape);

            // this.initialBackgroundImage = this.card.bgImage?.url
            //   ? this.card?.bgImage?.url + '?' + new Date().getTime()
            //   : null;

            this.setDailyRestriction(res);
            this.setIntervalRestriction(res);

            this.updateStampOptions(this.totalStampsFormControl.value);

            this.cardGradient = res.colors?.cardGradient;

            if (res.icons?.reward) {
              this.rewardIcon = res.icons?.reward!;
            }

            if (res.icons?.stamp) {
              this.stampIcon = res.icons?.stamp!;
            }

            this.card.locations?.sort((a, b) => a.name!.localeCompare(b.name!));

            this.rewardIcons = this.card.rewardIcons;

            this.getLocations();

            if (typeof this.card?.merchant != 'string') {
              const merchant = this.card.merchant as Merchant;
              if (merchant) {
                this.setCustomBrandingOption(merchant);
              }
            }

            if (this.card.customLogo?.url) {
              this.merchantLogo = this.card?.customLogo?.url;
              this.customLogo = this.card?.customLogo;
              this.showCustomLogo = true;
            } else {
              this.showCustomLogo = false;
            }

            this.cardTypeFormControl.setValue(
              this.cardTypeOptions.find(
                (el) => el.value === (this.card.cardType ?? CardType.stamp)
              )
            );

            if (this.card.cardType === CardType.point) {
              this.progressBarFormControl.setValue(
                this.card.colors?.progressBar
              );
              this.progressBarBgFormControl.setValue(
                this.card.colors?.progressBarBg
              );
              this.pointsExpire = this.card?.points?.expiry?.active ?? false;
              this.pointsExpiryFormControl.setValue(
                this.card?.points?.expiry?.days
              );

              this.totalPointsFormControl.setValue(
                this.card?.points?.totalPoints
              );
            }

            if (this.card.cardType !== CardType.stamp) {
              this.joinPointsFormControl.setValue(
                this.card?.points?.joinPoints
              );
              this.pointsPerScanFormControl.setValue(
                this.card?.points?.pointsPerScan
              );
            }

            if (this.card.cardType === CardType.openEndedPoint) {
              this.maxPointsFormControl.setValue(this.card?.points?.maxPoints);
            }

            this.setValidators();
          }
        },
        error: () => {
          this.router
            .navigate(['/' + Constants.routes.dashboard], {})
            .then(() => {
              window.location.reload();
            });
        },
      })
      .add(() => (this.cardLoading = false));
  }

  setDailyRestriction(res: any) {
    const dailyRestrictions = res.restrictions?.daily;
    this.stampsPerDay = dailyRestrictions?.enabled ?? false;
    this.stampsPerDayStampsFormControl.setValue(dailyRestrictions?.value || 1);
    this.selectedDailyOption =
      res.restrictions?.daily?.asset || this.constants.assets.tag;
  }

  setIntervalRestriction(res: any) {
    const intervalRestrictions = res.restrictions?.interval;
    this.interval = intervalRestrictions?.enabled ?? false;
    const hoursAndMinutes = this.splitIntoHoursAndMinutes(
      intervalRestrictions?.value ?? 0
    );

    this.intervalHoursFormControl.setValue(hoursAndMinutes.hours ?? 0);
    this.intervalMinutesFormControl.setValue(hoursAndMinutes.minutes ?? 0);

    this.selectedIntervalOption =
      res.restrictions?.interval?.asset || this.constants.assets.tag;
  }

  splitIntoHoursAndMinutes(value: number) {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    return { hours, minutes };
  }

  getShapeFromString(shapeString?: string): Shapes {
    return (Shapes as any)[shapeString ?? 'circle'] as Shapes;
  }

  openIconPickerDialog(reward = false, custom = false, item?: any): void {
    if (!this.canEditCard) {
      return;
    }

    let rewardIcon;
    let rewardImage;

    const existingIndex = this.rewardIcons?.findIndex(
      (el) => el.stamp === item?.stamp
    );

    const customReward = this.rewardIcons?.find(
      (el) => el.stamp === item?.stamp
    );

    if (customReward) {
      rewardIcon = customReward;
      rewardImage = customReward.image;
    } else {
      rewardIcon = this.rewardIcon;
      rewardImage = this.rewardImage ?? this.card.rewardImage;
    }

    const dialogRef = this.dialog.open(IconDialogComponent, {
      width: '600px',
      minHeight: '559.5px',
      autoFocus: false,
      disableClose: true,
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        filled: reward ? rewardIcon?.filled : this.stampIcon?.filled,
        isStampIcon: reward === false,
        iconImage: reward
          ? rewardImage?.content ?? rewardImage?.url
          : this.stampImage?.content ?? this.card.stampImage?.url,
        rewardIcon: rewardIcon?.web,
        stampIcon: this.stampIcon?.web,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (reward) {
          if (custom) {
            let obj = {};

            if (data.iconImage) {
              obj = {
                stamp: item?.stamp,
                image: data.iconImage,
              };
            } else {
              obj = {
                stamp: item?.stamp,
                mobile: data.mobile,
                web: data.web,
                filled: data.filled,
                image: null,
              };
            }

            if (existingIndex != undefined && existingIndex >= 0) {
              this.rewardIcons![existingIndex] = obj;
            } else {
              this.rewardIcons?.push(obj);
            }
          } else {
            if (data.iconImage) {
              this.rewardImage = data.iconImage;
              this.rewardIcon = undefined;
            } else {
              this.rewardImage = undefined;
              this.card.rewardImage = undefined;
              this.rewardIcon = data;
            }
          }
        } else {
          if (data.iconImage) {
            this.stampImage = data.iconImage;
            this.stampIcon = undefined;
          } else {
            this.stampImage = undefined;
            this.card.stampImage = undefined;
            this.stampIcon = data;
          }
        }
      }
    });
  }

  save(): void {
    this.submitted = true;

    if (this.form.invalid) {
      const invalidFields = [];
      for (const control in this.form.controls) {
        if (this.form.controls[control].invalid) {
          invalidFields.push(control);
        }
      }

      this.constants.snack('Please complete all required fields');
      return;
    }

    if (this.cardTypeFormControl.value.value === CardType.point) {
      if (
        this.joinPointsFormControl.value > this.totalPointsFormControl.value
      ) {
        this.constants.snack(
          'Join points cannot be greater than the total available points'
        );
        return;
      }

      if (
        this.pointsPerScanFormControl.value > this.totalPointsFormControl.value
      ) {
        this.constants.snack(
          'Points per scan cannot be greater than the total available points'
        );
        return;
      }
    }

    let locations = (this.locationFormControl.value as Location[])
      .filter((el) => el._id !== undefined && el._id !== null)
      .map((el) => el._id);

    if (!locations.length) {
      this.constants
        .callbackSnack('Please select at least one reward', true)
        .subscribe(() => {
          this.selectedIndex = this.tabs.findIndex((el) => el == 'rewards');
          this.selectedTab = 'rewards';
        });
      return;
    }

    if (!this.rewards.length) {
      this.constants
        .callbackSnack('Please select at least one reward', true)
        .subscribe(() => {
          this.selectedIndex = this.tabs.findIndex((el) => el == 'rewards');
          this.selectedTab = 'rewards';
        });
      return;
    }

    if (
      this.stampsPerDay &&
      (this.stampsPerDayStampsFormControl.value < 1 ||
        this.stampsPerDayStampsFormControl.value > 100)
    ) {
      this.constants.snack(
        'Stamps per day value must be between 1 - 100',
        true
      );
      return;
    }

    if (this.interval) {
      const intervalHours = this.intervalHoursFormControl.value;
      const intervalMinutes = this.intervalMinutesFormControl.value;
      const totalMinutes =
        (this.intervalHoursFormControl.value ?? 0) * 60 +
        (this.intervalMinutesFormControl.value ?? 0);

      if (totalMinutes === 0) {
        this.constants.snack('Interval must be greater than 0', true);
        return;
      }

      if (intervalHours < 0 || intervalHours > 24) {
        this.constants.snack('Interval hours must be between 0 - 24', true);
        return;
      }

      if (intervalMinutes < 0 || intervalMinutes > 60) {
        this.constants.snack('Interval minutes must be between 0 - 60', true);
        return;
      }

      if (totalMinutes > 1440) {
        this.constants.snack(
          'Total interval cannot exceed 24 hours (1,440 minutes)',
          true
        );
        return;
      }
    }

    // if (this.cardColorFormControl?.value && this.getContrastScore() < 4) {
    //   this.dialog.open(TwoOptionAlertComponent, {
    //     data: {
    //       title: 'Colour Contrast',
    //       body: 'Oops, it looks like the text colour and card surface contrast is too low, making it hard to read. Consider choosing a combination with better visibility for an optimal customer experience.',
    //       buttonTwo: 'OK',
    //     },
    //     autoFocus: false,
    //     width: '400px',
    //     // disableClose: true,
    //     panelClass: 'custom-dialog',
    //   });
    //   return;
    // }

    let intervalMinutes =
      (this.intervalHoursFormControl.value ?? 0) * 60 +
      (this.intervalMinutesFormControl.value ?? 0);

    const payload = {
      title: this.titleFormControl.value?.trim(),
      description: this.descriptionFormControl.value?.trim(),
      numberOfStamps: this.totalStampsFormControl.value,
      joinStamps: this.joinStampsFormControl.value,
      stampsPerScan: this.stampsPerScanStampsFormControl.value,
      active: true,
      colors: {
        card: this.cardColorFormControl.value,
        text: this.textColorFormControl.value,
        stampOutline: this.stampColorFormControl.value,
        stampFill: this.stampFillColorFormControl.value,
        stampFillEmpty: this.stampFillEmptyFormControl.value,
        icons: this.iconColorFormControl.value,
        cardGradient: this.cardGradient,
        progressBar: this.progressBarFormControl.value,
        progressBarBg: this.progressBarBgFormControl.value,
      },
      icons: {
        opacity: this.opacitySliderValue,
        stamp: this.stampIcon ?? null,
        reward: this.rewardIcon ?? null,
      },
      rewards: this.rewards.map((el) => ({
        stamp: el?.stamp,
        reward: el.reward._id,
        points: el?.points,
      })),
      points: {
        totalPoints: this.totalPointsFormControl.value,
        joinPoints: this.joinPointsFormControl.value,
        maxPoints: this.maxPointsFormControl.value,
        pointsPerScan: this.pointsPerScanFormControl.value,
        expiry: {
          active: this.pointsExpire,
          days: this.pointsExpiryFormControl.value,
        },
      },
      id: this.card._id,
      locations: locations,
      showLogo: this.showLogo,
      soundOn: this.soundOn,
      shape: this.shape.toString(),
      stampImage: this.stampImage ?? this.card.stampImage,
      rewardImage: this.rewardImage ?? this.card.rewardImage,
      restrictions: {
        daily: {
          enabled: this.stampsPerDay || false,
          value: this.stampsPerDayStampsFormControl.value || 1,
          asset: this.selectedDailyOption,
        },
        interval: {
          enabled: this.interval ?? false,
          value: intervalMinutes ?? 0,
          asset: this.selectedIntervalOption,
        },
      },
      displayAs: this.fromFormControl.value,
      customLogo: {},
      showCustomLogo: this.showCustomLogo,
      stampPromo: this.card.stampPromo,
      rewardIcons: this.rewardIcons,
      cardType: this.cardTypeFormControl?.value?.value,
    };

    if (this.showCustomLogo && this.customLogo) {
      payload.customLogo = this.customLogo!;
    }

    if (payload.stampImage) {
      payload.icons.stamp = null;
    }

    if (payload.rewardImage) {
      payload.icons.reward = null;
    }

    this.saving = true;

    if (this.editMode) {
      this.submit(this.cardService.editCard(payload), false);
    } else {
      this.submit(this.cardService.createCard(payload), true);
    }
  }

  submit(observable: Observable<any>, isNew: boolean): void {
    observable
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.router.navigate([Constants.routes.cards]).then(() => {
              if (isNew) {
                const existing = this.authService.getNestedUserProperty(
                  'merchant',
                  'onboard'
                );
                if (existing) {
                  this.authService.updateNestedProperty('merchant', 'onboard', {
                    location: existing.location ?? false,
                    reward: existing.reward ?? false,
                    card: true,
                  });
                  this.storageService.set('checklistVisible', String(true));
                }
                window.location.reload();
              }
            });
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  get tooltip(): string {
    if (this.loading) {
      return '';
    }
    if (!this.canCreateCard) {
      if (!this.locations?.length) {
        return this.constants.strings.addLocation;
      } else {
        return this.constants.strings.upgradePlanNotice;
      }
    } else {
      return '';
    }
  }

  totalPointsChanged(): void {
    this.rewards.forEach((reward: any) => {
      reward.points = this.totalPointsFormControl.value;
    });
  }

  maxPointsChanged(): void {
    // this.rewards.forEach((reward: any) => {
    //   reward.points = this.totalPointsFormControl.value;
    // });
  }

  openRewardDialog(): void {
    if (!this.canEditCard) {
      return;
    }

    const dialogRef = this.dialog.open(RewardDialogComponent, {
      data: {
        maxPoints: this.totalPointsFormControl.value,
        cardType: this.cardTypeFormControl?.value?.value,
        stampNumbers: Array.from(
          { length: this.totalStampsFormControl.value },
          (_, index) => index + 1
        )
          .filter((el: number) => {
            return !this.rewards.some((ex: any) => ex?.stamp === el);
          })
          .map((el: number) => ({ number: el })),
      },
      scrollStrategy: new NoopScrollStrategy(),
      width: '450px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (!this.isStampCard && data?.points === 0) {
          this.constants.snack('Points must be greater than 0');
        } else {
          this.rewards.push(data);
          this.sortRewards();
        }
      }
    });
  }

  removeReward(item: any): void {
    if (!this.canEditCard) {
      return;
    }

    const index = this.rewards.findIndex((el) => el === item);
    if (index !== -1) {
      this.rewards.splice(index, 1);
    }

    this.rewardIcons = this.rewardIcons?.filter(
      (el) => el.stamp !== item?.stamp
    );

    this.sortRewards();
  }

  get stampArray(): any[] {
    return Array.from(
      { length: this.totalStampsFormControl.value },
      (_, index) => index + 1
    );
  }

  get loading(): boolean {
    return (
      this.locationsLoading ||
      this.cardLoading ||
      this.planLimitsLoading ||
      this.saving
    );
  }

  borderRadius(shape: Shapes): string {
    if (shape === Shapes.circle) {
      return '50%';
    }
    if (shape === Shapes.rounded) {
      return '15%';
    }

    return '0%';
  }

  get shapesEnum(): typeof Shapes {
    return Shapes;
  }

  getContrastScore(): number {
    const luminance1 = this.calculateLuminance(this.textColorFormControl.value);
    const luminance2 = this.calculateLuminance(this.cardColorFormControl.value);

    const contrastRatio =
      (Math.max(luminance1, luminance2) + 0.05) /
      (Math.min(luminance1, luminance2) + 0.05);

    // Map the contrast ratio to a scale from 1 to 10
    const contrastScore = Math.round(
      this.mapContrastRatioToScore(contrastRatio)
    );

    return contrastScore;
  }

  private calculateLuminance(hexColor: string): number {
    const rgb = this.hexToRgb(hexColor);
    return (
      0.2126 * this.adjustGamma(rgb.r / 255) +
      0.7152 * this.adjustGamma(rgb.g / 255) +
      0.0722 * this.adjustGamma(rgb.b / 255)
    );
  }

  private hexToRgb(hex: string): { r: number; g: number; b: number } {
    const bigint = parseInt(hex?.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  }

  private adjustGamma(value: number): number {
    return value <= 0.03928
      ? value / 12.92
      : Math.pow((value + 0.055) / 1.055, 2.4);
  }

  private mapContrastRatioToScore(contrastRatio: number): number {
    return Math.min(Math.round(contrastRatio), 10);
  }

  hasLocations(): void {
    if (!this.locations.length) {
      this.constants.snack('Please add a location first');
    }
  }

  colorOptionPicked(option: any) {}

  openGradientDialog(): void {
    const dialogRef = this.dialog.open(GradientDialogComponent, {
      width: '400px',
      autoFocus: false,
      disableClose: true,
      data: {
        cardGradient: this.cardGradient,
      },
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.cardGradient = data;
        this.cardColorFormControl.setValue(null);
      }
    });
  }

  logEvent(event: any, trigger: any) {
    if (trigger === 'close' && event) {
      this.cardGradient = undefined;
    }
  }

  get restrictionTooltip(): string {
    return `The total number of stamps a customer can receive on this card in a given day, resets at midnight.
    
            Applies to NFC stamping only, the merchant can override this using the Portal or Remy Connect.`;
  }

  get intervalRestrictionTooltip(): string {
    return `The total time which must elapse between stamps, this can be used to reduce the risk that customers give themselves extra stamps when they shouldn't`;
  }

  get requiredStampsTooltip(): string {
    return `The required number of stamps for achieving the main reward on the customer's Stamp Card.
    
            For example, if the customer must buy 9 coffees to get their 10th free, you should choose 9 stamps which means the customer will get their reward on stamp number 9 which lets them have their 10th for free`;
  }

  get availablePointsTooltip(): string {
    return `The required number of points for achieving the main reward on the customer's Loyalty Card`;
  }

  get maxPointsTooltip(): string {
    return `The maximum number of points that can be achieved on the customer's Loyalty Card. Leave blank for no limit`;
  }

  get cardTypeTooltip(): string {
    return `Choose between a stamp card or a points card`;
  }

  get displayAsTooltip(): string {
    return `This is the name which will be displayed in the customer's card wallet.
    
            You can either leave this as your Merchant name, or set it to display the name of one of your locations - if you have set a custom logo for your location then this will also be used`;
  }

  get isStampCard(): boolean {
    return this.cardTypeFormControl.value?.value === CardType.stamp;
  }

  get isPointsCard(): boolean {
    return this.cardTypeFormControl.value?.value === CardType.point;
  }

  get isOpenEndedPointsCard(): boolean {
    return this.cardTypeFormControl.value?.value === CardType.openEndedPoint;
  }

  get hasSelectedCardType(): boolean {
    return this.cardTypeFormControl.value?.label !== undefined;
  }

  setCustomBrandingOption(merchant: Merchant) {
    if (merchant.customBranding) {
      this.showCustomBrandingOption = true;
    } else {
      this.showCustomBrandingOption = false;
    }
  }

  // getBorderRadius(inner = false): string {
  //   if (this.shape === Shapes.circle) {
  //     return '50%';
  //   }
  //   if (this.shape === Shapes.rounded) {
  //     if (inner) {
  //       return '11%';
  //     } else {
  //       return '15%';
  //     }
  //   }

  //   return '0%';
  // }

  // getIsFilled(index: number): boolean {
  //   return (
  //     (this.isReward(index)
  //       ? this.rewardIcon?.filled
  //       : this.stampIcon?.filled) ?? false
  //   );
  // }

  // isReward(index: number): boolean {
  //   for (const reward of this.rewards) {
  //     const i = index + 1;
  //     if (reward?.stamp?.number === i || reward?.stamp === i) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  // getIcon(index: number): string {
  //   return this.isReward(index) ? this.rewardIcon?.web! : this.stampIcon?.web!;
  // }

  displayAsSelected(displayAs: SendAs) {
    if (displayAs.type == 'location') {
      const location = this.locations.find((el) => el.name === displayAs.name);
      if (location && location?.logo?.url) {
        this.merchantLogo = location?.logo?.url;
        this.customLogo = location?.logo;
        this.showCustomLogo = true;
      }
    } else {
      this.merchantLogo = this.authService.getMerchantLogo();
      this.customLogo = undefined;
      this.showCustomLogo = false;
    }
  }

  removeStampFillColour(): void {
    this.stampFillColorFormControl.setValue(null);
  }

  openRevertDialog(original: any): void {
    const dialogRef = this.dialog.open(StampsPerScanComponent, {
      data: {
        title: 'Stamps per scan',
        originalStamps: original,
        stampPromo: this.card.stampPromo,
        newStamps: this.stampsPerScanStampsFormControl.value,
        buttonOne: 'Cancel',
        buttonTwo: 'Save',
      },
      autoFocus: false,
      disableClose: true,
      width: '400px',
      panelClass: 'custom-dialog',
    });

    dialogRef.afterClosed().subscribe((data: any) => {
      if (data?.option == 1) {
        this.card.stampPromo = data.promo;
      } else {
        this.stampsPerScanStampsFormControl.setValue(this.card.stampsPerScan);
      }
    });
  }

  get stampPromoString(): string | null {
    const promo = this.card.stampPromo;

    if (!promo || !promo.end) {
      return null;
    }

    const endDate = new Date(promo.end!);
    const formattedDate = endDate.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    if (this.card.stampPromo?.active) {
      return `Reverts back to ${this.card.stampPromo.originalStamps} ${
        this.card.stampPromo.originalStamps === 1 ? ' stamp' : ' stamps'
      } on ${formattedDate}`;
    } else {
      return null;
    }
  }

  customRewardIcon(reward: any) {
    let customReward = this.rewardIcons?.find(
      (el) => el.stamp === reward?.stamp
    );

    if (customReward) {
      return customReward;
    }

    return this.rewardIcon;
  }

  customRewardImage(reward: any) {
    let customReward = this.rewardIcons?.find(
      (el) => el.stamp === reward?.stamp
    );

    if (customReward && customReward?.web) {
      return null;
    }

    if (customReward && customReward?.image) {
      return customReward?.image?.content ?? customReward?.image?.url;
    }

    return this.rewardImage?.content ?? this.card?.rewardImage?.url;
  }

  get canSelectReward(): boolean {
    if (!this.isStampCard) {
      if (this.rewards?.length) {
        return true;
      } else {
        if (!this.isOpenEndedPointsCard) {
          if (!(this.totalPointsFormControl.value > 0)) {
            return false;
          }
          if (!(this.pointsPerScanFormControl.value > 0)) {
            return false;
          }
        }
      }

      return true;
    } else {
      return true;
    }
  }

  setCanEditCard() {
    this.canEditCard = (!this.editMode || this.card._canManage) ?? false;

    if (!this.canEditCard) {
      this.titleFormControl.disable();
      this.descriptionFormControl.disable();
      this.fromFormControl.disable();
      this.totalStampsFormControl.disable();
      this.totalPointsFormControl.disable();
      this.joinStampsFormControl.disable();
      this.joinPointsFormControl.disable();
      this.pointsPerScanFormControl.disable();
      this.stampsPerScanStampsFormControl.disable();
      this.pointsExpiryFormControl.disable();
    }
  }
}
