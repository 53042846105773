import { Component, Input } from '@angular/core';
import { Constants } from 'src/app/app.constants';
import { CardType, Shapes } from '../../../models/index';

@Component({
  selector: 'card-preview-container',
  templateUrl: './card-preview-container.component.html',
})
export class CardPreviewContainerComponent {
  constructor(public constants: Constants) {}

  @Input() totalPoints: number = 300;
  @Input() maxPoints?: number;
  @Input() currentPoints: number = 125;
  @Input() cardType: CardType = CardType.stamp;
  @Input() stampImage?: any;
  @Input() rewardImage?: any;
  @Input() textColour: string = '';
  @Input() cardColour: string = '';
  @Input() stampColour: string = '';
  @Input() stampFillEmpty: string = '';
  @Input() stampOutlineColour: string = '';
  @Input() iconColor: string = '';
  @Input() rewardIcon?: any;
  @Input() rewardIcons?: any;
  @Input() stampIcon?: any;
  @Input() stamps: any[] = [];
  @Input() rewards: any[] = [];
  @Input() title?: string;
  @Input() description?: string;
  @Input() iconOpacity: number = 0.1;
  @Input() showLogo: boolean = true;
  @Input() showStamps: boolean = false;
  @Input() shape: Shapes = Shapes.square;
  @Input() merchantLogo?: string | undefined;
  @Input() cardGradient?: {
    from: string;
    to: string;
    angle: number;
    value: number;
  };
  @Input() progressBarColor?: string = '';
  @Input() progressBarBgColor?: string = '';

  ngOnInit() {}

  get isStampCard(): boolean {
    return this.cardType === CardType.stamp;
  }
}
