import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { map, Observable, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { TwoOptionAlertComponent } from './shared/components/two-option-alert/two-option-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { Constants } from './app.constants';
import { environment } from 'src/environments/environment';
import {
  GoogleAnalyticsService,
  UserService,
  StorageService,
  AuthService,
  MerchantService,
} from './services/index';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  Event,
} from '@angular/router';
import { Merchant } from './models/merchant';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { AnalyticsAbstract } from './services/analytics/analytics.abstract';
import { Crisp } from 'crisp-sdk-web';
import { RequestNFCDialogComponent } from './features/nfc/request-nfc-dialog/request-nfc-dialog.component';
import { GiveRewardDialogComponent } from './features/customer/give-reward-dialog/give-reward-dialog.component';
import { AutomationsDialogComponent } from './features/engagement/automations_dialog/automations_dialog.component';
import { PermissionsService } from './services/permissions.service';

declare const gtag: Function;

enum QuickActionTypes {
  message,
  reward,
  export,
  nfc,
  connect,
  remy,
  birthday,
  support,
  plans,
}

interface QuickAction {
  label: string;
  icon: string;
  type: QuickActionTypes;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css', './_mat_overrides.css'],
})
export class AppComponent {
  expandedMenuItem: string = '';
  quickActions: QuickAction[] = [
    {
      label: 'Send a Message',
      icon: 'message',
      type: QuickActionTypes.message,
    },
    { label: 'Give a Reward', icon: 'redeem', type: QuickActionTypes.reward },
    {
      label: 'Export Data',
      icon: 'system_update_alt',
      type: QuickActionTypes.export,
    },
    {
      label: 'Birthday Club',
      icon: 'celebration',
      type: QuickActionTypes.birthday,
    },
    {
      label: 'Request an NFC Card',
      icon: 'nfc',
      type: QuickActionTypes.nfc,
    },
  ];

  currentScreen = '';
  collapsedSidebar = false;
  // trialBannerHidden = false;
  hasTrial = false;
  trialEnds: Date = new Date();
  numberOfDaysLeft = 0;
  uid = 'anon';
  merchantName?: string;

  navigating = false;
  menuOptions: any[] = [];

  title = 'remy';
  selectedMenu: string = '';
  @ViewChild('drawer') drawer: any;
  menuList: any[] = [];
  menuList2: any[] = [];
  userName?: string;
  userRole?: string;
  email?: string;

  fetchingUser = false;
  userIsAuthenticated = false;
  private authListenerSubs?: Subscription;

  mobileDrawerOpen = false;

  merchantLogo?: string | undefined;
  isAdmin = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private storageService: StorageService,
    public authService: AuthService,
    private userService: UserService,
    private router: Router,
    public dialog: MatDialog,
    public constants: Constants,
    private cdref: ChangeDetectorRef,
    private ga: GoogleAnalyticsService,
    private mixpanel: AnalyticsAbstract,
    public merchantService: MerchantService,
    public ps: PermissionsService
  ) {
    this.isAdmin = this.ps.adminOrMerchant;

    if (window.location.href.includes('demo')) {
      this.currentScreen = 'demo';
      return;
    }

    this.init();

    if (this.ps.permissions.billing.manage()) {
      this.quickActions.push({
        label: 'View Plans & Billing',
        icon: 'credit_card',
        type: QuickActionTypes.plans,
      });
    }
  }

  actionClicked(action: QuickAction): void {
    if (action.type === QuickActionTypes.birthday) {
      this.dialog.open(AutomationsDialogComponent, {
        data: {},
        scrollStrategy: new NoopScrollStrategy(),
        autoFocus: false,
        width: '600px',
        disableClose: true,
        panelClass: 'custom-dialog',
      });
    }
    if (action.type === QuickActionTypes.connect) {
      window.open('https://remyrewards.co.uk/mapp', '_blank');
    }
    if (action.type === QuickActionTypes.remy) {
      window.open('https://remyrewards.co.uk/app', '_blank');
    }
    if (action.type === QuickActionTypes.export) {
      this.router.navigate([Constants.routes.reports]);
    }
    if (action.type === QuickActionTypes.message) {
      this.router.navigate([Constants.routes.engagePush]);
    }
    if (action.type === QuickActionTypes.nfc) {
      this.dialog.open(RequestNFCDialogComponent, {
        width: '450px',
        maxHeight: '85vh',
        autoFocus: false,
        scrollStrategy: new NoopScrollStrategy(),
      });
    }
    if (action.type === QuickActionTypes.plans) {
      this.router.navigate([Constants.routes.plans]);
    }
    if (action.type === QuickActionTypes.reward) {
      this.dialog.open(GiveRewardDialogComponent, {
        scrollStrategy: new NoopScrollStrategy(),
        width: '450px',
        autoFocus: false,
        panelClass: 'custom-dialog-panel',
        disableClose: true,
      });
    }
    if (action.type === QuickActionTypes.support) {
    }
    this.expandedMenuItem = '';
  }

  setExpanded(menuItem: string) {
    this.expandedMenuItem = menuItem;
  }

  init() {
    this.collapsedSidebar = this.storageService.get('drawer') === 'true';

    this.menuList = [
      {
        title: this.constants.strings.dashboard,
        image: '../assets/menu/db.svg',
      },
      {
        title: this.constants.strings.locations,
        image: '../assets/menu/locations.svg',
      },
      {
        title: this.constants.strings.rewards,
        image: '../assets/menu/rewards.svg',
      },
      {
        title: this.constants.strings.loyaltyCards,
        image: '../assets/menu/cards.svg',
      },
      {
        title: this.constants.strings.customers,
        image: '../assets/menu/customers.svg',
        permission: () => this.ps.permissions.customers.view(),
      },
      {
        title: this.constants.strings.team,
        image: '../assets/menu/team.svg',
        permission: () => this.ps.permissions.team.view(),
      },
      {
        title: this.constants.strings.engage,
        image: '../assets/menu/megaphone.svg',
        permission: () => this.ps.permissions.engage.view(),
      },
      {
        title: this.constants.strings.nfcCards,
        image: '../assets/menu/nfc.svg',
      },
    ];

    this.menuList2 = [
      {
        title: this.constants.strings.reports,
        image: '../assets/menu/reports.svg',
      },
      {
        title: this.constants.strings.referrals,
        image: '../assets/menu/referral.svg',
        permission: () => this.ps.adminOrMerchant,
      },
      {
        title: this.constants.strings.help,
        image: '../assets/menu/help.svg',
      },
    ];

    this.menuOptions = [
      { title: this.constants.strings.profile, icon: 'account_circle' },
      {
        title: this.constants.strings.plansAndBilling,
        icon: 'shopping_bag',
        permission: () => this.ps.permissions.billing.manage(),
      },
      {
        title: this.constants.strings.integrations,
        icon: 'cable',
        permission: () => this.ps.permissions.integrations.manage(),
      },
      { title: this.constants.strings.logout, icon: 'logout' },
    ];

    this.menuList = this.menuList.filter(
      (item) => !item.permission || item.permission()
    );

    this.menuList2 = this.menuList2.filter(
      (item) => !item.permission || item.permission()
    );

    this.menuOptions = this.menuOptions.filter(
      (item) => !item.permission || item.permission()
    );

    this.setupGoogleAnalytics();
  }

  setupGoogleAnalytics(): void {
    if (environment.production) {
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          gtag('config', environment.googleAnalyticsID, {
            page_path: event.urlAfterRedirects,
            user_id: this.uid,
          });
        }

        switch (true) {
          case event instanceof NavigationStart: {
            this.navigating = true;
            break;
          }

          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this.navigating = false;
            break;
          }
          default: {
            break;
          }
        }
      });
    }
  }

  getNotificationCount(): void {
    this.userService.getNotificationCount().subscribe({
      next: (res: any) => {
        if (res) {
          this.merchantService.unreadNotifications = res.count ?? 0;
        }
      },
      error: (res: HttpErrorResponse) => {},
    });
  }

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result: BreakpointState) => {
        if (result.matches) {
          this.storageService.setLocal('collapsed', 'false');
        } else {
          this.storageService.setLocal('collapsed', 'true');
        }
        return result.matches;
      })
    );

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  close(): void {
    if (this.mobileDrawerOpen) {
      this.mobileDrawerOpen = false;
      this.drawer.close();
    }
  }

  ngAfterViewInit() {
    if (this.currentScreen === 'demo') {
      return;
    }

    document
      .getElementById('content')
      ?.addEventListener('pointerdown', (event: { pointerType: string }) => {
        if (event.pointerType === 'mouse') {
          this.close();
        }
        if (event.pointerType === 'touch') {
          this.close();
        }
        if (event.pointerType === 'pen') {
          this.close();
        }
      });

    Crisp.user.setEmail(
      this.authService.getIsAuth()
        ? this.authService.getUserProperty('email')
        : 'Anon'
    );
    Crisp.user.setNickname(
      this.authService.getIsAuth()
        ? this.authService.getUserProperty('name')
        : 'Anon'
    );
  }

  // addUsetifulScriptConditionally() {
  //   const condition = true;

  //   if (condition) {
  //     const script = this._renderer2.createElement('script');
  //     script.type = 'text/javascript';
  //     script.async = true;
  //     script.src = 'https://www.usetiful.com/dist/usetiful.js';
  //     script.setAttribute('id', 'usetifulScript');
  //     script.setAttribute('data-token', '6bd240667fabcfa0cc7939ad708386f7');

  //     this._renderer2.appendChild(document.head, script);
  //   }
  // }

  ngOnInit() {
    // this.addUsetifulScriptConditionally();

    if (this.currentScreen === 'demo') {
      return;
    }

    this.authService.autoAuthUser();

    this.userIsAuthenticated = this.authService.getIsAuth();

    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe((auth) => {
        this.userIsAuthenticated = auth.auth;

        if (auth.auth) {
          this.setUserData();
        }
      });

    this.setUserData();

    if (
      window.location.pathname === '/' ||
      window.location.pathname.includes(
        this.constants.strings.dashboard.toLowerCase()
      )
    ) {
      this.selectedMenu = this.constants.strings.dashboard;
    } else if (window.location.pathname.includes('location')) {
      this.selectedMenu = this.constants.strings.locations;
    } else if (window.location.pathname.includes('reward')) {
      this.selectedMenu = this.constants.strings.rewards;
    } else if (window.location.pathname.includes('nfc')) {
      this.selectedMenu = this.constants.strings.nfcCards;
    } else if (window.location.pathname.includes('card')) {
      this.selectedMenu = this.constants.strings.loyaltyCards;
    } else if (window.location.pathname.includes('customer')) {
      this.selectedMenu = this.constants.strings.customers;
    } else if (window.location.pathname.includes('team')) {
      this.selectedMenu = this.constants.strings.team;
    } else if (window.location.pathname.includes('engage')) {
      this.selectedMenu = this.constants.strings.engage;
    } else if (window.location.pathname.includes('help')) {
      this.selectedMenu = this.constants.strings.help;
    } else if (window.location.pathname.includes('report')) {
      this.selectedMenu = this.constants.strings.reports;
    } else if (window.location.pathname.includes('referral')) {
      this.selectedMenu = this.constants.strings.referrals;
    }

    this.loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=places&loading=async`
    );
  }

  loadScript(name: string) {
    return new Promise<void>((resolve, reject) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = name;
      script.async = true;
      script.defer = true;
      document.getElementsByTagName('head')[0].appendChild(script);
      resolve();
    });
  }

  async setUserData() {
    if (this.userIsAuthenticated) {
      this.getNotificationCount();
      await this.getUserDetails()
        .then(() => {})
        .catch((error: any) => {
          console.error('Error fetching user details:', error);
          this.authService.logout();
        });
    }
  }

  getExistingUserObj(existingUserObj: any): any {
    return {
      uid: existingUserObj.uid,
      token: existingUserObj.token,
      name: existingUserObj.name,
      merchant: existingUserObj.merchant,
      userType: existingUserObj.userType,
    };
  }

  async getUserDetails() {
    return new Promise((resolve, reject) => {
      this.fetchingUser = true;
      this.userService
        .getCurrentUser()
        .subscribe({
          next: (res: any) => {
            if (res) {
              const userString = this.storageService.get('user');
              if (!userString) {
                console.error('User not found in localStorage');
                reject('User not found in localStorage');
                return;
              }

              let existingUserObj;
              try {
                existingUserObj = JSON.parse(userString);
              } catch (e) {
                console.error('Invalid user data in localStorage', e);
                reject('Invalid user data in localStorage');
                return;
              }

              let newUserObj;
              try {
                newUserObj = this.getExistingUserObj(existingUserObj);
              } catch (e) {
                console.error(e);
                reject(e);
                return;
              }

              this.uid = res._id;

              this.mixpanel.identifyUser(res);

              newUserObj['uid'] = res._id;
              newUserObj['name'] = res.name;
              newUserObj['merchant'] = res.merchant;
              newUserObj['firstName'] = res.firstName;
              newUserObj['email'] = res.email;
              newUserObj['userType'] = res.userType;
              newUserObj['hasTrial'] = res.hasTrial;
              newUserObj['isOwner'] = res.isOwner;
              newUserObj['hasFree'] = res.hasFree;
              newUserObj['trialEnds'] = res.trialEnds;
              newUserObj['plan'] = res.plan;
              newUserObj['onboarded'] = res?.onboarded ?? false;
              newUserObj['canViewCustomers'] = res?.canViewCustomers ?? true;

              const userType = res.userType;
              if (userType === this.constants.userTypes.admin) {
                this.userRole = 'System Admin';
              } else if (userType === this.constants.userTypes.merchant) {
                this.userRole = 'Admin';
              } else {
                this.userRole = 'Location Manager';
              }

              this.userName = res.name;
              this.email = res.email;

              this.merchantName = res.merchant?.name;
              this.trialEnds = new Date(res.trialEnds);
              this.numberOfDaysLeft = this.constants.calculateDiff(
                this.trialEnds
              );
              this.hasTrial = res.hasTrial;

              this.storageService.set('user', JSON.stringify(newUserObj));

              this.merchantLogo = this.setMerchantLogo(res.merchant);

              resolve(newUserObj);
            } else {
              reject('Server error');
            }
          },
          error: (res: HttpErrorResponse) => {
            reject(res);
          },
        })
        .add(() => (this.fetchingUser = false));
    });
  }

  setMerchantLogo(merchant: Merchant): string {
    if (merchant?.logo && merchant?.logo?.url) {
      return merchant?.logo?.url + '?' + new Date().getTime();
    } else {
      return 'assets/remyIcon.png';
    }
  }

  ngOnDestroy() {
    this.authListenerSubs?.unsubscribe();
  }

  seePlans(): void {
    this.router.navigate([Constants.routes.plans]);
  }

  closeSideNav(item: string) {
    if (this.drawer._mode == 'over') {
      this.drawer.close();
    }

    const route = this.getRoute(item);
    this.ga.event('nav_' + route.toLowerCase());

    this.mobileDrawerOpen = false;

    if (route !== 'logout') {
      if (
        route.toLowerCase() === 'engage' &&
        this.merchantService.unreadNotifications > 0
      ) {
        this.router.navigate(['/' + route.toLowerCase()], {
          queryParams: { tab: 'conversations' },
        });
      } else {
        this.router.navigate(['/' + route.toLowerCase()]);
      }

      this.selectedMenu = item;
    }

    this.mixpanel.track(Constants.analytics_keys.selectSidebarMenu, {
      Item: item,
    });

    this.getNotificationCount();
  }

  getRoute(item: string): string {
    if (item === this.constants.strings.loyaltyCards) {
      return 'cards';
    }
    if (item === this.constants.strings.nfcCards) {
      return 'nfc';
    } else if (item === this.constants.strings.help) {
      // return 'https://remy-rewards.notion.site/remy-rewards/Remy-FAQ-4e083f6284f6499691b92bdb8885a329';
      return 'help';
    } else {
      return item.toLowerCase();
    }
  }

  get showSidenav(): boolean {
    if (this.storageService.getLocal('collapsed')) {
      return this.storageService.getLocal('collapsed') == 'true';
    } else {
      return true;
    }
  }

  toggleSidenav(): void {
    if (this.mobileDrawerOpen) {
      this.mobileDrawerOpen = false;
      this.drawer.close();
    } else {
      this.mobileDrawerOpen = true;
      this.drawer.open();
    }
  }

  onClick(event: any) {
    this.closeSideNav(event);

    if (event === this.constants.strings.logout) {
      this.ga.event('logout');
      const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
        data: {
          title: 'Logout',
          body: 'Are you sure you want to logout?',
          buttonOne: 'Cancel',
          buttonTwo: 'Yes',
        },
        scrollStrategy: new NoopScrollStrategy(),
        autoFocus: false,
        width: '350px',
        disableClose: true,
        panelClass: 'custom-dialog',
      });
      dialogRef.afterClosed().subscribe((option: number) => {
        if (option == 1) {
          this.authService.logout(true);
        }
      });
    }
    if (event === this.constants.strings.profile) {
      this.ga.event('view_profile');
      this.router.navigate([Constants.routes.profile]);
    }
    if (event === this.constants.strings.integrations) {
      this.ga.event('view_integrations');
      this.router.navigate([Constants.routes.integrations]);
    }
    if (event === this.constants.strings.plansAndBilling) {
      this.ga.event('view_plans');
      this.seePlans();
    }
  }

  toggleDrawer() {
    this.collapsedSidebar = !this.collapsedSidebar;
    this.storageService.set('drawer', `${this.collapsedSidebar}`);
  }

  // hideTrialBanner(): void {
  //   this.trialBannerHidden = !this.trialBannerHidden;
  //   this.storageService.set('trialBanner', `${this.trialBannerHidden}`);
  // }

  // get showTrialBanner(): boolean {
  //   if (!this.hasTrial) {
  //     return false;
  //   }

  //   if (!this.userIsAuthenticated) {
  //     return false;
  //   }

  //   if (this.trialBannerHidden) {
  //     return false;
  //   }
  //   return true;
  // }
}
