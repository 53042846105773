import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { CardService } from '../../../services/index';
import { StampCard } from '../../../models/index';

@Component({
  selector: 'configuration-dialog',
  templateUrl: './configuration-dialog.component.html',
  styleUrls: ['./configuration-dialog.component.css'],
})
export class ConfigurationDialogComponent implements OnInit {
  form!: FormGroup;
  skuFormControl!: FormControl;
  cardFormControl!: FormControl;
  typeFormControl!: FormControl;
  valueFormControl!: FormControl;
  stampsFormControl!: FormControl;
  loading = false;
  cards: StampCard[] = [];
  singleStampOrder = true;

  types: any[] = [
    { name: 'Single Product', value: 'product' },
    { name: 'Order Value', value: 'order' },
  ];
  editMode = false;

  constructor(
    private cardService: CardService,
    public matDialogRef: MatDialogRef<ConfigurationDialogComponent>,
    public fb: FormBuilder,
    private constants: Constants,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data?.configuration) {
      if (this.data.type === 'Zapier') {
        this.types = [{ name: 'Order Value', value: 'order' }];
      }
      if (this.data.type === 'Eposnow') {
        this.types = [{ name: 'Order Value', value: 'order' }];
      }
      if (this.data.type === 'Storekit') {
        this.types = [{ name: 'Order Value', value: 'order' }];
      }
      if (this.data.type === 'Squarespace') {
        this.types = [{ name: 'Order Value', value: 'order' }];
      }

      this.editMode = true;
    } else {
      if (!this.data.orderAvailable) {
        this.types = [{ name: 'Single Product', value: 'product' }];
      }

      if (
        this.data.type === 'Zapier' ||
        this.data.type === 'Eposnow' ||
        this.data.type === 'Storekit' ||
        this.data.type === 'Squarespace'
      ) {
        this.types = [{ name: 'Order Value', value: 'order' }];
      }
    }

    this.skuFormControl = new FormControl({
      value: this.data.configuration?.sku,
      disabled: false,
    });

    this.valueFormControl = new FormControl({
      value: this.data.configuration?.value,
      disabled: false,
    });

    this.stampsFormControl = new FormControl(
      {
        value: this.data.configuration?.numberOfStamps ?? 1,
        disabled: false,
      },
      [Validators.required]
    );

    this.cardFormControl = new FormControl({ value: '', disabled: false }, [
      Validators.required,
    ]);

    this.typeFormControl = new FormControl(
      {
        value: this.types.find(
          (el) => el.value === this.data?.configuration?.type
        ),
        disabled:
          this.editMode ||
          this.data.type === 'Zapier' ||
          this.data.type === 'Eposnow' ||
          this.data.type === 'Storekit' ||
          this.data.type === 'Squarespace',
      },
      [Validators.required]
    );

    if (
      this.data.type === 'Zapier' ||
      this.data.type === 'Eposnow' ||
      this.data.type === 'Storekit' ||
      this.data.type === 'Squarespace'
    ) {
      this.typeFormControl.setValue(this.types[0]);
    }

    this.form = this.fb.group({
      sku: this.skuFormControl,
      card: this.cardFormControl,
      type: this.typeFormControl,
      value: this.valueFormControl,
      'Number of stamps': this.stampsFormControl,
    });
  }

  ngOnInit(): void {
    this.getCards();
  }

  getCards(): void {
    this.loading = true;
    this.cardService
      .getCards(100, 0)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.cards = res.docs;

            const titleCounts = this.cards.reduce((acc, card) => {
              if (card.title) {
                acc[card.title] = (acc[card.title] || 0) + 1;
              }
              return acc;
            }, {} as Record<string, number>);

            this.cards.forEach((el) => {
              if (el.title && titleCounts[el.title] > 1) {
                el.title = `${el.title} (#${el.ref})`;
              }
            });

            this.cards.forEach((card) => {
              card.title = `${card.title} (${card.numberOfStamps} stamps)`;
            });

            this.cardFormControl.setValue(
              this.cards.find(
                (el) => el._id?.toString() === this.data?.configuration?.card
              )
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  save(): void {
    if (this.form.invalid) {
      const invalidFields = Object.keys(this.form.controls).filter(
        (field) => this.form.controls[field].invalid
      );

      this.constants.snack(
        'Please complete all fields: ' + invalidFields.join(', ')
      );
      return;
    }

    if (this.stampsFormControl.value < 1) {
      this.constants.snack('Number of stamps cannot be less than 1');
      return;
    }

    if (
      this.stampsFormControl.value > this.cardFormControl.value?.numberOfStamps
    ) {
      this.constants.snack(
        'Number of stamps cannot be more than available stamps for this card'
      );
      return;
    }

    const data = {
      type: this.typeFormControl.value.value,
      card: this.cardFormControl.value._id,
      numberOfStamps: this.stampsFormControl.value ?? 1,
      sku: null,
      value: null,
      _id: null,
    };

    if (this.editMode) {
      data['_id'] = this.data.configuration._id;
    }

    if (data.type === 'product') {
      data['sku'] = this.skuFormControl.value?.trim();
    } else {
      data['value'] = this.valueFormControl.value;
    }

    this.matDialogRef.close(data);
  }

  get infoBody(): string {
    return `Use this form to configure the basis upon which your customers should earn stamps for purchases of specific products on your <b>${this.data.type}</b> store or based on order value`;
  }

  get skuTooltip(): string {
    if (this.data.type === 'Shopify') {
      return `You can find this in your Shopify Product's overview page`;
    } else if (this.data.type === 'Square') {
      return `You can find this in your Square Item's overview page`;
    } else {
      return `You can find this in your SumUp Product's overview page`;
    }
  }

  typeChanged(): void {
    if (this.typeFormControl.value?.value === 'product') {
      this.skuFormControl.setValidators([Validators.required]);
      this.valueFormControl.clearValidators();
    } else {
      this.valueFormControl.setValidators([Validators.required]);
      this.skuFormControl.clearValidators();
    }

    this.skuFormControl.updateValueAndValidity();
    this.valueFormControl.updateValueAndValidity();
  }

  get stampNumbers() {
    return Array.from(
      { length: this.cardFormControl.value.numberOfStamps },
      (_, i) => i + 1
    );
  }
}
