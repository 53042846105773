import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { Location } from '../../../models/index';
import {
  LocationService,
  MerchantService,
  AuthService,
} from '../../../services/index';
import { TwoOptionAlertComponent } from 'src/app/shared/components/two-option-alert/two-option-alert.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

enum Destination {
  location,
  address,
}

@Component({
  selector: 'request-nfc-dialog',
  templateUrl: './request-nfc-dialog.component.html',
  styleUrls: ['./request-nfc-dialog.component.css'],
})
export class RequestNFCDialogComponent implements OnInit {
  form!: FormGroup;

  countries: string[] = [];
  destinations: { label: string; value: Destination }[] = [];
  requesting = false;

  colorFormControl!: FormControl;
  destinationFormControl!: FormControl;
  locationFormControl!: FormControl;
  countryFormControl!: FormControl;
  lineOneFormControl!: FormControl;
  lineTwoFormControl!: FormControl;
  cityFormControl!: FormControl;
  postcodeFormControl!: FormControl;

  colors: any = ['White', 'Black'];
  locations: Location[] = [];
  loading = true;
  locationsLoading = false;
  destinationEnum: typeof Destination = Destination;
  merchantActive = false;

  constructor(
    private constants: Constants,
    private http: HttpClient,
    public matDialogRef: MatDialogRef<RequestNFCDialogComponent>,
    public fb: FormBuilder,
    private dialog: MatDialog,
    private authService: AuthService,
    private merchantService: MerchantService,
    private locationService: LocationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.initForm();

    this.destinations = [
      { label: 'A new address', value: Destination.address },
    ];
  }

  ngOnInit(): void {
    this.merchantActive = this.authService.merchantObj?.active ?? true;

    this.loadCountries();
    this.getLocations();
  }

  initForm() {
    // Initialize the form and controls before making any requests
    this.form = this.fb.group({
      color: ['', Validators.required],
      destination: ['', Validators.required],
      location: [''],
      country: ['', Validators.required],
      lineOne: ['', Validators.required],
      lineTwo: [''],
      city: ['', Validators.required],
      postcode: ['', Validators.required],
    });

    this.colorFormControl = this.form.get('color') as FormControl;
    this.destinationFormControl = this.form.get('destination') as FormControl;
    this.locationFormControl = this.form.get('location') as FormControl;
    this.countryFormControl = this.form.get('country') as FormControl;
    this.lineOneFormControl = this.form.get('lineOne') as FormControl;
    this.lineTwoFormControl = this.form.get('lineTwo') as FormControl;
    this.cityFormControl = this.form.get('city') as FormControl;
    this.postcodeFormControl = this.form.get('postcode') as FormControl;
  }

  loadCountries() {
    this.http
      .get<any>('../../../../assets/json/countries.json')
      .subscribe((data) => {
        this.countries = data;
        this.loading = false;
      });
  }

  colorOptionTapped(option: number) {
    this.colorFormControl.setValue(option === 0 ? 'White' : 'Black');
  }

  primaryTapped() {
    if (this.form?.invalid) {
      this.constants.snack('Please complete all required fields');
      this.form.markAllAsTouched();

      Object.keys(this.form.controls).forEach((field) => {
        const control = this.form.get(field);
        if (control?.invalid) {
          console.log(
            `Field: ${field}, Errors: ${JSON.stringify(control.errors)}`
          );
        }
      });
      return;
    }
    this.requestNFC({
      color: this.colorFormControl.value,
      country: this.countryFormControl.value?.name,
      lineOne: this.lineOneFormControl.value,
      lineTwo: this.lineTwoFormControl.value,
      city: this.cityFormControl.value,
      postcode: this.postcodeFormControl.value,
    });
  }

  requestNFC(data: any) {
    this.requesting = true;
    this.merchantService
      .requestNFCCard(data)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.matDialogRef.close();

            const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
              data: {
                title: 'Confirmation',
                body: 'Your request for an NFC card has been received, we will notify you via email when it has been dispatched.<br><br>If you have any questions in the meantime, get in touch at <b>hello@remyrewards.co.uk</b>',
                buttonTwo: 'OK',
              },
              autoFocus: false,
              disableClose: true,
              width: '400px',
              panelClass: 'custom-dialog',
              scrollStrategy: new NoopScrollStrategy(),
            });

            dialogRef.afterClosed().subscribe((data) => {
              window.location.reload();
            });
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.requesting = false));
  }

  selectedDestinationType() {
    const selection = this.destinations.find(
      (el) => el.label === this.destinationFormControl.value.label
    );

    if (selection?.value === Destination.location) {
      this.locationFormControl.setValidators([Validators.required]);
    } else {
      this.locationFormControl.clearValidators();
      this.lineOneFormControl.setValidators([Validators.required]);
    }
  }

  locationSelected(location: Location) {
    this.countryFormControl.setValue({ name: location.address?.country });
    this.cityFormControl.setValue(location.address?.city);
    this.lineOneFormControl.setValue(location.address?.lineOne);
    this.lineTwoFormControl.setValue(location.address?.lineTwo);
    this.postcodeFormControl.setValue(location.address?.postcode);

    if (location.address?.type === 1) {
      //private premises
      this.lineOneFormControl.clearValidators();
      this.lineTwoFormControl.clearValidators();
    } else {
      this.lineOneFormControl.setValidators([Validators.required]);
      this.lineTwoFormControl.setValidators([Validators.required]);
    }
  }

  getLocations(): void {
    this.locationsLoading = true;

    const subscription =
      this.authService.getUserProperty('userType') ===
      this.constants.userTypes.manager
        ? this.merchantService.getStaffLocations()
        : this.locationService.getLocations(1000, 0);

    subscription
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.locations = res?.docs ?? res;
            this.locations?.sort((a, b) => a.name!.localeCompare(b.name!));

            console.log(this.locations);

            if (this.locations?.length) {
              this.destinations.unshift({
                label: 'One of my saved locations',
                value: Destination.location,
              });
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.locationsLoading = false));
  }
}
