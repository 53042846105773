import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { MerchantService } from '../../../services/index';
import { ExternalOrder } from '../../../models/index';
import { InvitedUser } from 'src/app/models/invitedUser';

@Component({
  selector: 'invite-dialog',
  templateUrl: './invite-dialog.component.html',
  styleUrls: ['./invite-dialog.component.css'],
})
export class InviteDialogComponent implements OnInit {
  loading = false;
  pageIndex = 0;
  pageSize = 10;
  length = 0;
  invites: InvitedUser[] = [];
  range = '';
  error = false;
  scrollTimeout: any;
  suppressPaging: boolean = false;
  @ViewChild('myTable') table: any;

  constructor(
    public constants: Constants,
    private merchantService: MerchantService,
    public matDialogRef: MatDialogRef<InviteDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.getInvites();
  }

  getInvites(): void {
    this.loading = true;
    this.merchantService
      .getInvites(this.pageSize, this.pageIndex)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.length = res.totalDocs;
            this.invites = res.docs;

            this.range = this.constants.pageCounter(
              this.pageIndex,
              this.pageSize,
              this.length,
              this.invites?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  get emptyString(): string {
    return `No invites to display`;
  }

  pageEvent(event: any) {
    if (!this.suppressPaging) {
      this.pageIndex = event.offset;
      this.getInvites();
    }
  }

  handleScroll() {
    this.suppressPaging = true;

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.suppressPaging = false;
    }, 100);
  }
}
