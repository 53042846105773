import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { StampEvent, Redemption } from '../../../models/index';
import { AuthService, MerchantService } from '../../../services/index';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'nfc-card-detail',
  templateUrl: './nfc-card-detail.component.html',
  styleUrls: ['./nfc-card-detail.component.css'],
})
export class NFCCardDetailComponent {
  @Input() smallTitle = false;
  @Input() title = '';
  @Input() subtitle: string = '';
  @ViewChild('stampsTable') stampsTable: any;
  @ViewChild('pointsTable') pointsTable: any;
  @ViewChild('redemptionsTable') redemptionsTable: any;

  tabs = ['stamps', 'points', 'redemptions'];
  selectedTab = 'stamps';
  selectedIndex = 0;

  scrollTimeout: any;
  suppressPaging: boolean = false;
  stampPageIndex = 0;
  stampPageSize = 10;
  stampLength = 0;

  pointsIndex = 0;
  pointsPageSize = 10;
  pointsLength = 0;

  redemptionPageIndex = 0;
  redemptionPageSize = 10;
  redemptionLength = 0;

  nfcEvents: StampEvent[] = [];
  pointsEvents: StampEvent[] = [];
  nfcRedemptions: Redemption[] = [];

  stampsLoading = false;
  redemptionsLoading = false;

  error = false;
  nfcRef = '';
  stampEventsRange = '';
  pointsRange = '';
  redemptionsRange = '';
  canViewCustomers = false;

  constructor(
    public constants: Constants,
    private authService: AuthService,
    private merchantService: MerchantService,
    private route: ActivatedRoute,
    private router: Router,
    private ps: PermissionsService
  ) {
    this.route.params.subscribe((params) => (this.nfcRef = params['id']));

    this.canViewCustomers = this.ps.permissions.customers.view();

    this.route.queryParams.subscribe((params) => {
      const tab = params['tab'];

      if (tab) {
        this.selectedTab = tab;
        this.selectedIndex = this.tabs.findIndex((el) => el === tab);
      }
    });
  }

  ngOnInit() {
    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');
    this.getNFCStamps(this.selectedTab === 'points');
    this.getNFCRedemptions();
  }

  get emptyString(): string {
    return `No ${this.constants.strings.nfcEvents} to display`;
  }

  getNFCStamps(points = false): void {
    this.stampsLoading = true;
    this.merchantService
      .getNFCStamps(
        this.nfcRef,
        points ? this.pointsPageSize : this.stampPageSize,
        points ? this.pointsIndex : this.stampPageIndex,
        points
      )
      .subscribe({
        next: (res: any) => {
          if (res) {
            if (points) {
              this.pointsLength = res.totalDocs;
              this.pointsEvents = res.docs;

              this.pointsRange = this.constants.pageCounter(
                this.pointsIndex,
                this.pointsPageSize,
                this.pointsLength,
                this.pointsEvents?.length
              );
            } else {
              this.stampLength = res.totalDocs;
              this.nfcEvents = res.docs;

              this.stampEventsRange = this.constants.pageCounter(
                this.stampPageIndex,
                this.stampPageSize,
                this.stampLength,
                this.nfcEvents?.length
              );
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.stampsLoading = false));
  }

  getNFCRedemptions(): void {
    this.redemptionsLoading = true;
    this.merchantService
      .getNFCRedemptions(
        this.nfcRef,
        this.redemptionPageSize,
        this.redemptionPageIndex
      )
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.redemptionLength = res.totalDocs;
            this.nfcRedemptions = res.docs;

            this.redemptionsRange = this.constants.pageCounter(
              this.redemptionPageIndex,
              this.redemptionPageSize,
              this.redemptionLength,
              this.nfcRedemptions?.length
            );
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.redemptionsLoading = false));
  }

  stampPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.stampPageIndex = event.offset;
      this.getNFCStamps();
    }
  }

  pointsPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.pointsIndex = event.offset;
      this.getNFCStamps(true);
    }
  }

  redemptionPageEvent(event: any) {
    if (!this.suppressPaging) {
      this.redemptionPageIndex = event.offset;
      this.getNFCRedemptions();
    }
  }

  tabChanged(index: any): void {
    let tab = this.tabs[index];

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false,
    });

    this.selectedTab = tab;

    this.loadData();
  }

  loadData(): void {
    if (this.selectedTab === 'stamps') {
      this.getNFCStamps(false);
    }
    if (this.selectedTab === 'points') {
      this.getNFCStamps(true);
    }
    if (this.selectedTab === 'redemptions') {
      this.getNFCRedemptions();
    }
  }

  get loading(): boolean {
    return this.stampsLoading || this.redemptionsLoading;
  }

  handleScroll() {
    this.suppressPaging = true;

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.suppressPaging = false;
    }, 100);
  }

  isLocationNotString(events: any[]): boolean {
    return events?.length > 0 && typeof events[0]?.location !== 'string';
  }
}
