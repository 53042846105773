<div
  [ngClass]="title ? 'loading-indicator-container' : 'loading-indicator'"
  *ngIf="loading"
>
  <p
    *ngIf="title"
    style="
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0px;
    "
  >
    {{ title }}
  </p>
  <ng-lottie
    [width]="title ? '100px' : '150px'"
    [options]="options"
  ></ng-lottie>
</div>
