<div
  class="form-field-label"
  *ngIf="label"
  [ngStyle]="{
    'margin-bottom': subtitle ? '0px' : '8px'
  }"
>
  {{ label }}<span *ngIf="isMandatory" style="color: red">*</span
  ><span> <info-icon *ngIf="infoText" [text]="infoText"></info-icon></span>
</div>

<link-text
  *ngIf="subtitle"
  [text]="subtitle"
  (onTapped)="subtitleTapped.emit()"
></link-text>

<mat-form-field appearance="outline" style="width: 100%">
  <mat-select
    [formControl]="formCtrl"
    (selectionChange)="selection($event)"
    [(value)]="value"
  >
    <mat-select-trigger>
      {{ value?.[property ?? ''] || value }}
    </mat-select-trigger>

    <input
      *ngIf="showSearch"
      (click)="$event.stopPropagation()"
      class="search-input"
      autocomplete="off"
      [formControl]="searchFormControl"
      type="text"
      placeholder="Search..."
      (ngModelChange)="onTypeSearch()"
    />

    <mat-option
      [disabled]="disabled"
      *ngFor="let option of searchActive ? filteredOptions : options"
      [value]="option"
    >
      <img
        *ngIf="showIcon"
        [src]="getOptionIcon(option)"
        style="margin-right: 5px"
      />
      <span [innerHTML]="this.property ? option[this.property] : option"></span>

      <div *ngIf="inBrackets">
        <span
          *ngIf="inBrackets"
          style="color: grey; font-size: 12px"
          [innerHTML]="option[inBrackets]"
        ></span>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
