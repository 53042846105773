<page-header
  [title]="(editMode ? 'Edit ' : 'New ') + constants.strings.loyaltyCard"
  [subtitle]="subtitle"
></page-header>

<div
  style="display: flex; justify-content: flex-end; margin-bottom: 10px"
  *ngIf="canEditCard"
>
  <div style="display: flex">
    <app-primary-button
      [title]="constants.strings.cancel"
      border=" 1px solid var(--app-container)"
      bgColor="transparent"
      color="white"
      (tapped)="back()"
    ></app-primary-button>

    <div style="margin-left: 10px">
      <app-primary-button
        title="Save"
        [disabled]="loading || !canCreateCard"
        border=" 1px solid var(--app-container)"
        [tooltip]="tooltip"
        (tapped)="save()"
      ></app-primary-button>
    </div>
  </div>
</div>

<div *ngIf="isHandset$ | async" style="margin-bottom: 20px">
  <card-preview-container
    [textColour]="textColorFormControl.value"
    [cardColour]="cardColorFormControl.value"
    [stampColour]="stampFillColorFormControl.value"
    [stampFillEmpty]="stampFillEmptyFormControl.value"
    [stampOutlineColour]="stampColorFormControl.value"
    [stamps]="stampArray"
    [stampIcon]="stampIcon"
    [rewardIcon]="rewardIcon"
    [rewardIcons]="rewardIcons"
    [rewards]="rewards"
    [title]="titleFormControl.value"
    [description]="descriptionFormControl.value"
    [iconOpacity]="opacitySliderValue / 10"
    [iconColor]="iconColorFormControl.value"
    [showLogo]="showLogo"
    [showStamps]="showStamps"
    [shape]="shape"
    [cardGradient]="cardGradient"
    [stampImage]="stampImage?.content ?? card.stampImage?.url"
    [rewardImage]="rewardImage?.content ?? card.rewardImage?.url"
    [merchantLogo]="merchantLogo"
    [cardType]="cardTypeFormControl.value?.value"
    [currentPoints]="(totalPointsFormControl.value ?? 300) * 0.8"
    [totalPoints]="totalPointsFormControl.value ?? 300"
    [maxPoints]="maxPointsFormControl.value"
    [progressBarColor]="progressBarFormControl.value"
    [progressBarBgColor]="progressBarBgFormControl.value"
  >
    ></card-preview-container
  >
</div>

<form [formGroup]="form" *ngIf="!loading">
  <div
    class="main-container"
    style="display: flex; justify-content: stretch; margin-bottom: 70px"
  >
    <div
      class="detail-container"
      id="left"
      style="width: 100%; margin-right: 20px"
    >
      <mat-tab-group
        mat-align-tabs="start"
        mat-stretch-tabs="false"
        disableRipple="true"
        [animationDuration]="0"
        [(selectedIndex)]="selectedIndex"
        (selectedTabChange)="tabChanged($event.index)"
      >
        <mat-tab label="Overview">
          <div class="col-lg-7">
            <div class="form-field" style="margin-top: 20px">
              <single-select
                label="Card type"
                [infoText]="cardTypeTooltip"
                [formCtrl]="cardTypeFormControl"
                [options]="cardTypeOptions"
                [property]="'label'"
                [inBrackets]="'info'"
                [value]="cardTypeFormControl.value"
                [disabled]="editMode"
                (selected)="cardTypeSelected()"
              >
              </single-select>
            </div>

            <div class="form-field">
              <div class="form-field-label">
                Title<span style="color: red">*</span>
              </div>
              <input
                class="form-input"
                placeholder="e.g. Free coffee"
                autocomplete="off"
                [formControl]="titleFormControl"
                maxlength="38"
                type="text"
                [ngStyle]="{
                  border:
                    titleFormControl.invalid && submitted
                      ? '1px solid red'
                      : '1px solid var(--app-light-grey)'
                }"
              />
            </div>

            <div class="form-field" style="margin-top: 20px">
              <div class="form-field-label">
                Description<span style="color: red">*</span>
              </div>
              <textarea
                class="form-input"
                autocomplete="off"
                [formControl]="descriptionFormControl"
                placeholder="e.g. Buy 9 coffees, get your 10th free!"
                type="text"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                [maxlength]="78"
                [rows]="2"
                [ngStyle]="{
                  border:
                    descriptionFormControl.invalid && submitted
                      ? '1px solid red'
                      : '1px solid var(--app-light-grey)'
                }"
              ></textarea>
            </div>

            <div
              class="form-field"
              style="margin-top: 20px"
              *ngIf="showCustomBrandingOption"
            >
              <single-select
                label="Display as"
                [infoText]="displayAsTooltip"
                [formCtrl]="fromFormControl"
                [options]="fromOptions"
                property="name"
                [value]="fromFormControl.value"
                (selected)="displayAsSelected($event)"
              ></single-select>
            </div>

            <div class="form-field" *ngIf="(locations ?? []).length > 1">
              <multiple-select
                label="Locations"
                [formCtrl]="locationFormControl"
                [options]="locations"
                [disabled]="disabledLocations"
                [showAll]="false"
                infoText="Use this to restrict the locations which this card can be used at"
                value="name"
                (click)="hasLocations()"
              >
              </multiple-select>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Theme" *ngIf="hasSelectedCardType">
          <div class="form-field" style="margin-top: 20px">
            <div class="form-field-label">Colours</div>

            <div style="display: flex; margin-top: 10px">
              <div
                class="colour-container"
                style="margin-right: 20px"
                *ngIf="isStampCard"
              >
                <div
                  ngx-colors-trigger
                  [colorPickerControls]="'no-alpha'"
                  [colorsAnimation]="'popup'"
                  [format]="'hex'"
                  [formControl]="iconColorFormControl"
                  class="colour-avatar"
                  [ngStyle]="{ 'background-color': iconColorFormControl.value }"
                ></div>
                <div class="colour-label">Icons</div>
              </div>

              <div
                class="colour-container"
                style="margin-right: 20px"
                *ngIf="isPointsCard"
              >
                <div
                  ngx-colors-trigger
                  [colorPickerControls]="'no-alpha'"
                  [colorsAnimation]="'popup'"
                  [format]="'hex'"
                  [formControl]="progressBarFormControl"
                  class="colour-avatar"
                  [ngStyle]="{
                    'background-color': progressBarFormControl.value
                  }"
                ></div>
                <div class="colour-label">Progress Bar</div>
              </div>

              <div class="colour-container" style="margin-right: 20px">
                <div
                  [mat-menu-trigger-for]="colorMenu"
                  class="colour-avatar"
                  [ngStyle]="
                    cardColorFormControl.value
                      ? {
                          background: cardColorFormControl.value,
                          cursor: 'pointer'
                        }
                      : {
                          background:
                            'linear-gradient(' +
                            cardGradient?.angle +
                            'deg, ' +
                            cardGradient?.from +
                            ' ' +
                            0 +
                            '%, ' +
                            cardGradient?.from +
                            ' ' +
                            cardGradient?.value +
                            '%, ' +
                            cardGradient?.to +
                            ' ' +
                            100 +
                            '%)',
                          cursor: 'pointer'
                        }
                  "
                ></div>
                <div class="colour-label">Card</div>
              </div>

              <div class="colour-container" style="margin-right: 20px">
                <div
                  [formControl]="textColorFormControl"
                  ngx-colors-trigger
                  [colorPickerControls]="'no-alpha'"
                  [colorsAnimation]="'popup'"
                  [format]="'hex'"
                  class="colour-avatar"
                  [ngStyle]="{ 'background-color': textColorFormControl.value }"
                ></div>
                <div class="colour-label">Text</div>
              </div>
            </div>

            <div style="display: flex; margin-top: 10px">
              <div
                class="colour-container"
                style="margin-right: 20px"
                *ngIf="isStampCard"
              >
                <div
                  [mat-menu-trigger-for]="stampOutlineMenu"
                  class="colour-avatar"
                  [ngStyle]="{
                    'background-color': stampColorFormControl.value
                      ? stampColorFormControl.value
                      : 'transparent',
                    cursor: 'pointer'
                  }"
                ></div>
                <div class="colour-label">Stamp outline</div>
              </div>
              <div
                class="colour-container"
                style="margin-right: 20px"
                *ngIf="isPointsCard"
              >
                <div
                  ngx-colors-trigger
                  [colorPickerControls]="'no-alpha'"
                  [colorsAnimation]="'popup'"
                  [format]="'hex'"
                  [formControl]="progressBarBgFormControl"
                  class="colour-avatar"
                  [ngStyle]="{
                    'background-color': progressBarBgFormControl.value
                  }"
                ></div>
                <div class="colour-label">Progress Bar (Background)</div>
              </div>

              <div
                class="colour-container"
                style="margin-right: 20px"
                *ngIf="isStampCard"
              >
                <div
                  [mat-menu-trigger-for]="stampFillMenu"
                  class="colour-avatar"
                  [ngStyle]="{
                    'background-color': stampFillColorFormControl.value
                      ? stampFillColorFormControl.value
                      : 'transparent',
                    cursor: 'pointer'
                  }"
                ></div>
                <div class="colour-label">Stamp fill</div>
              </div>

              <div
                class="colour-container"
                style="margin-right: 20px"
                *ngIf="isStampCard"
              >
                <div
                  [mat-menu-trigger-for]="stampFillEmptyMenu"
                  class="colour-avatar"
                  [ngStyle]="{
                    'background-color': stampFillEmptyFormControl.value
                      ? stampFillEmptyFormControl.value
                      : 'transparent',
                    cursor: 'pointer'
                  }"
                ></div>
                <div class="colour-label">Stamp fill (pre stamp)</div>
              </div>
            </div>
          </div>

          <div class="form-field" style="margin-top: 20px" *ngIf="isStampCard">
            <div class="form-field-label">Stamp shape</div>

            <div style="display: flex; margin-top: 10px">
              <div
                class="shape-container"
                style="margin-right: 20px"
                (click)="shape = shapesEnum.square"
              >
                <div
                  class="shape-avatar-outer"
                  [ngStyle]="{
                    'border-radius': borderRadius(shapesEnum.square),
                    'border-color':
                      shape === shapesEnum.square
                        ? selectedShapeBorderColor
                        : ''
                  }"
                >
                  <div
                    class="shape-avatar"
                    [ngStyle]="{
                      'border-radius': borderRadius(shapesEnum.square)
                    }"
                  ></div>
                </div>
              </div>
              <div
                class="shape-container"
                style="margin-right: 20px"
                (click)="shape = shapesEnum.circle"
              >
                <div
                  class="shape-avatar-outer"
                  [ngStyle]="{
                    'border-radius': borderRadius(shapesEnum.circle),
                    'border-color':
                      shape === shapesEnum.circle
                        ? selectedShapeBorderColor
                        : ''
                  }"
                >
                  <div
                    class="shape-avatar"
                    [ngStyle]="{
                      'border-radius': borderRadius(shapesEnum.circle)
                    }"
                  ></div>
                </div>
              </div>
              <div
                class="shape-container"
                style="margin-right: 20px"
                (click)="shape = shapesEnum.rounded"
              >
                <div
                  class="shape-avatar-outer"
                  [ngStyle]="{
                    'border-radius': borderRadius(shapesEnum.rounded),
                    'border-color':
                      shape === shapesEnum.rounded
                        ? selectedShapeBorderColor
                        : ''
                  }"
                >
                  <div
                    class="shape-avatar"
                    [ngStyle]="{
                      'border-radius': borderRadius(shapesEnum.rounded)
                    }"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-field" style="margin-top: 20px" *ngIf="isStampCard">
            <div class="form-field-label">Icons</div>
            <div style="display: flex; margin-top: 10px">
              <div class="colour-container" style="margin-right: 20px">
                <div class="icon-avatar" (click)="openIconPickerDialog(false)">
                  <mat-icon
                    *ngIf="!stampImage?.content && !card.stampImage?.url"
                    class="icon"
                    [fontSet]="
                      stampIcon?.filled
                        ? 'material-icons'
                        : 'material-icons-outlined'
                    "
                    >{{ stampIcon?.web }}</mat-icon
                  >
                  <img
                    *ngIf="stampImage?.content || card.stampImage?.url"
                    style="width: 25px; height: 25px"
                    [src]="stampImage?.content ?? card.stampImage?.url"
                  />
                </div>
                <div class="colour-label">Stamp</div>
              </div>
              <div class="colour-container" style="margin-right: 20px">
                <div class="icon-avatar" (click)="openIconPickerDialog(true)">
                  <mat-icon
                    *ngIf="!rewardImage?.content && !card.rewardImage?.url"
                    class="icon"
                    [fontSet]="
                      rewardIcon?.filled
                        ? 'material-icons'
                        : 'material-icons-outlined'
                    "
                    >{{ rewardIcon?.web }}</mat-icon
                  >
                  <img
                    *ngIf="rewardImage?.content || card.rewardImage?.url"
                    style="width: 25px; height: 25px"
                    [src]="rewardImage?.content ?? card.rewardImage?.url"
                  />
                </div>
                <div class="colour-label">Reward</div>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab
          [label]="isStampCard ? 'Stamping' : 'Points'"
          *ngIf="hasSelectedCardType"
        >
          <div style="overflow: hidden">
            <div class="row" *ngIf="isStampCard">
              <div class="col-lg-4">
                <div class="form-field" style="margin-top: 20px">
                  <single-select
                    label="Total stamps"
                    [infoText]="requiredStampsTooltip"
                    [formCtrl]="totalStampsFormControl"
                    [options]="totalStampsOptions"
                    [value]="totalStampsFormControl.value"
                    (selected)="updateStampOptions($event)"
                  >
                  </single-select>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-field" style="margin-top: 20px">
                  <single-select
                    label="Join stamps"
                    infoText="The initial number of free stamps your customers should receive upon taking your Stamp Card. Choose 0 for no joining stamps"
                    [formCtrl]="joinStampsFormControl"
                    [options]="joinStampsOptions"
                    [value]="joinStampsFormControl.value"
                  >
                  </single-select>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-field" style="margin-top: 20px">
                  <single-select
                    label="Stamps per scan"
                    infoText="The number of stamps to be awarded by a single scan, you can set an automation to revert back to the original number"
                    [formCtrl]="stampsPerScanStampsFormControl"
                    [options]="stampsPerScanOptions"
                    [value]="stampsPerScanStampsFormControl.value"
                    (selected)="
                      openRevertDialog(
                        card.stampPromo?.active
                          ? card.stampPromo?.originalStamps
                          : card.stampsPerScan
                      )
                    "
                  >
                  </single-select>
                  <p
                    class="revert-string"
                    *ngIf="stampPromoString"
                    (click)="
                      openRevertDialog(card.stampPromo?.originalStamps ?? 1)
                    "
                  >
                    {{ stampPromoString }}
                  </p>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="!isStampCard">
              <div class="col-lg-4">
                <div
                  class="form-field"
                  style="margin-top: 20px"
                  *ngIf="isPointsCard"
                >
                  <div class="form-field-label">
                    Total points<span style="color: red">*</span
                    ><info-icon [text]="availablePointsTooltip"></info-icon>
                  </div>
                  <input
                    class="form-input"
                    autocomplete="off"
                    placeholder="e.g. 300"
                    [formControl]="totalPointsFormControl"
                    type="number"
                    (change)="totalPointsChanged()"
                    [maxlength]="7"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    [ngStyle]="{
                      border:
                        totalPointsFormControl.invalid && submitted
                          ? '1px solid red'
                          : '1px solid var(--app-light-grey)'
                    }"
                  />
                </div>
                <div
                  class="form-field"
                  style="margin-top: 20px"
                  *ngIf="isOpenEndedPointsCard"
                >
                  <div class="form-field-label">
                    Max points<info-icon [text]="maxPointsTooltip"></info-icon>
                  </div>
                  <input
                    class="form-input"
                    autocomplete="off"
                    placeholder="(leave empty for no limit)"
                    [formControl]="maxPointsFormControl"
                    type="number"
                    (change)="maxPointsChanged()"
                    [maxlength]="7"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-field" style="margin-top: 20px">
                  <div class="form-field-label">
                    Join points<span style="color: red">*</span
                    ><span
                      ><info-icon
                        text="The initial number of points your customers should receive upon taking your Loyalty Card. Choose 0 for no joining points"
                      ></info-icon
                    ></span>
                  </div>
                  <input
                    class="form-input"
                    autocomplete="off"
                    placeholder="e.g. 20"
                    [formControl]="joinPointsFormControl"
                    type="number"
                    [maxlength]="7"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-field" style="margin-top: 20px">
                  <div class="form-field-label">
                    Points per scan<span style="color: red">*</span
                    ><info-icon
                      text="The number of points to be awarded by a single scan, you can set an automation to revert back to the original number"
                    ></info-icon>
                  </div>
                  <input
                    class="form-input"
                    autocomplete="off"
                    placeholder="e.g. 20"
                    [formControl]="pointsPerScanFormControl"
                    type="number"
                    [maxlength]="7"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    [ngStyle]="{
                      border:
                        pointsPerScanFormControl.invalid && submitted
                          ? '1px solid red'
                          : '1px solid var(--app-light-grey)'
                    }"
                  />
                </div>
              </div>
            </div>

            <div class="row" *ngIf="!isStampCard" style="margin-top: 20px">
              <div class="col-lg-8">
                <div
                  class="form-field"
                  style="display: flex; justify-content: space-between"
                >
                  <div class="form-field-label">Points have an expiry date</div>
                  <mat-slide-toggle
                    [disabled]="!canEditCard"
                    [checked]="pointsExpire"
                    (change)="pointsExpire = !pointsExpire"
                  >
                  </mat-slide-toggle>
                </div>

                <div class="form-field" *ngIf="pointsExpire">
                  <div style="width: 100%">
                    <div class="form-field-label">
                      Expiry<span style="color: red">*</span
                      ><span
                        ><info-icon
                          text="The expiration period for the points, measured in days from the time the customer receives them"
                        ></info-icon>
                      </span>
                    </div>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;
                        align-items: center;
                      "
                    >
                      <input
                        class="form-input"
                        autocomplete="off"
                        placeholder="e.g. 14"
                        [formControl]="pointsExpiryFormControl"
                        type="number"
                        [maxlength]="4"
                        min="0"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      />
                      <div
                        class="form-field-label"
                        style="width: 50px; padding-left: 10px"
                      >
                        days
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="isStampCard">
              <div class="col-lg-8">
                <hr />
                <div
                  class="form-field"
                  style="
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <div class="form-field-label">Daily stamp restriction</div>
                  <mat-slide-toggle
                    [disabled]="!canEditCard"
                    [checked]="stampsPerDay"
                    (change)="stampsPerDay = !stampsPerDay"
                  >
                  </mat-slide-toggle>
                </div>

                <div
                  class="form-field"
                  style="margin-left: 10px; margin-right: 10px"
                  *ngIf="stampsPerDay"
                >
                  <div style="width: 100%">
                    <div class="form-field-label">
                      Max stamps per day<span style="color: red">*</span
                      ><span
                        ><info-icon [text]="restrictionTooltip"></info-icon>
                      </span>
                    </div>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;
                        align-items: center;
                      "
                    >
                      <input
                        class="form-input"
                        autocomplete="off"
                        placeholder="e.g. 1"
                        [formControl]="stampsPerDayStampsFormControl"
                        type="number"
                        [maxlength]="3"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      />
                      <div
                        class="form-field-label-subtitle"
                        style="
                          width: 60px;
                          padding-left: 10px;
                          margin-bottom: 0px;
                        "
                      >
                        stamps
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-8">
                <hr *ngIf="stampsPerDay" />
                <div
                  class="form-field"
                  style="
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <div class="form-field-label">Interval restriction</div>
                  <mat-slide-toggle
                    [disabled]="!canEditCard"
                    [checked]="interval"
                    (change)="interval = !interval"
                  >
                  </mat-slide-toggle>
                </div>

                <div
                  class="form-field"
                  style="margin-left: 10px; margin-right: 10px"
                  *ngIf="interval"
                >
                  <div class="form-field-label">
                    Time between stamps<span style="color: red">*</span
                    ><span
                      ><info-icon
                        [text]="intervalRestrictionTooltip"
                      ></info-icon>
                    </span>
                  </div>

                  <div class="row">
                    <div class="col-lg-6">
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          flex-direction: row;
                          align-items: center;
                          margin-bottom: 10px;
                        "
                      >
                        <input
                          class="form-input"
                          autocomplete="off"
                          placeholder="e.g. 30"
                          [formControl]="intervalHoursFormControl"
                          type="number"
                          [maxlength]="2"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        />
                        <div
                          class="form-field-label-subtitle"
                          style="
                            width: 60px;
                            padding-left: 10px;
                            margin-bottom: 0px;
                          "
                        >
                          hours
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          flex-direction: row;
                          align-items: center;
                        "
                      >
                        <input
                          class="form-input"
                          autocomplete="off"
                          placeholder="e.g. 30"
                          [formControl]="intervalMinutesFormControl"
                          type="number"
                          [maxlength]="2"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        />
                        <div
                          class="form-field-label-subtitle"
                          style="
                            width: 60px;
                            padding-left: 10px;
                            margin-bottom: 0px;
                          "
                        >
                          minutes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Rewards" *ngIf="hasSelectedCardType && canSelectReward">
          <div style="overflow: hidden">
            <div class="col-lg-6">
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-top: 20px;
                  justify-content: space-between;
                "
              >
                <div style="display: flex; align-items: center">
                  <div
                    class="form-field-label"
                    style="margin-bottom: 0px; margin-right: 5px"
                  >
                    Add a Reward
                  </div>

                  <mat-icon
                    *ngIf="!isPointsCard || rewards?.length === 0"
                    class="status-icon"
                    style="color: var(--app-timber); cursor: pointer"
                    (click)="openRewardDialog()"
                    >add_circle</mat-icon
                  >
                </div>

                <div
                  class="form-field-label"
                  style="margin-bottom: 0px"
                  *ngIf="
                    rewards?.length && (isStampCard || rewards?.length === 0)
                  "
                >
                  Edit Icon
                </div>
              </div>

              <div
                *ngFor="let item of rewards; let i = index"
                style="margin-top: 10px"
              >
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      margin-right: 20px;
                    "
                    class="social-container"
                  >
                    <div style="display: flex; align-items: center">
                      <div
                        class="form-field-label"
                        style="
                          margin-bottom: 0px !important;
                          color: black;
                          font-size: 16px;
                          margin-right: 5px;
                        "
                      >
                        {{ isStampCard ? item?.stamp : item?.points }}:
                      </div>
                      <div
                        class="form-field-label"
                        style="
                          margin-bottom: 0px !important;
                          color: black;
                          font-size: 16px;
                        "
                      >
                        {{ item?.reward?.title }}
                      </div>
                    </div>

                    <mat-icon
                      style="color: red; cursor: pointer"
                      (click)="removeReward(item)"
                      >cancel
                    </mat-icon>
                  </div>

                  <div
                    *ngIf="isStampCard"
                    style="cursor: pointer"
                    (click)="openIconPickerDialog(true, true, item)"
                  >
                    <mat-icon
                      *ngIf="!customRewardImage(item)"
                      class="icon"
                      [fontSet]="
                        customRewardIcon(item)?.filled
                          ? 'material-icons'
                          : 'material-icons-outlined'
                      "
                      >{{ customRewardIcon(item)?.web }}</mat-icon
                    >
                    <img
                      *ngIf="customRewardImage(item)"
                      style="width: 25px; height: 25px"
                      [src]="customRewardImage(item)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div>
      <card-preview-container
        *ngIf="!(isHandset$ | async)"
        [textColour]="textColorFormControl.value"
        [cardColour]="cardColorFormControl.value"
        [stampColour]="stampFillColorFormControl.value"
        [stampFillEmpty]="stampFillEmptyFormControl.value"
        [stampOutlineColour]="stampColorFormControl.value"
        [stamps]="stampArray"
        [stampIcon]="stampIcon"
        [rewardIcon]="rewardIcon"
        [rewardIcons]="rewardIcons"
        [rewards]="rewards"
        [title]="titleFormControl.value"
        [description]="descriptionFormControl.value"
        [iconOpacity]="opacitySliderValue / 10"
        [iconColor]="iconColorFormControl.value"
        [showLogo]="showLogo"
        [showStamps]="showStamps"
        [shape]="shape"
        [cardGradient]="cardGradient"
        [stampImage]="stampImage?.content ?? card.stampImage?.url"
        [rewardImage]="rewardImage?.content ?? card.rewardImage?.url"
        [merchantLogo]="merchantLogo"
        [cardType]="cardTypeFormControl.value?.value"
        [currentPoints]="(totalPointsFormControl.value ?? 300) * 0.65"
        [totalPoints]="totalPointsFormControl.value ?? 300"
        [maxPoints]="maxPointsFormControl.value"
        [progressBarColor]="progressBarFormControl.value"
        [progressBarBgColor]="progressBarBgFormControl.value"
      ></card-preview-container>
    </div>
  </div>
</form>

<loader [loading]="loading"></loader>

<mat-menu #colorMenu="matMenu" class="custom-menu">
  <button
    ngx-colors-trigger
    [colorPickerControls]="'no-alpha'"
    [colorsAnimation]="'popup'"
    [format]="'hex'"
    [formControl]="cardColorFormControl"
    class="menu-list-item"
    style="border-radius: 5px; position: relative"
    mat-menu-item
  >
    <div
      style="
        display: flex;
        justify-content: start;
        align-items: center;
        position: relative;
      "
    >
      <div
        style="
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100px;
          height: 40px;
        "
      ></div>
      <div
        style="
          width: 15px;
          height: 15px;
          border-radius: 50%;
          font-size: 14px;
          margin-right: 5px;
          background-color: var(--app-timber);
          z-index: 0;
        "
      ></div>
      <span style="font-size: 14px; z-index: 0">Solid</span>
    </div>
  </button>

  <button
    class="menu-list-item"
    style="border-radius: 5px"
    mat-menu-item
    (click)="openGradientDialog()"
  >
    <div
      style="
        display: flex;
        justify-content: start;
        align-items: center;
        position: relative;
      "
    >
      <div
        style="
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100px;
          height: 40px;
        "
      ></div>
      <div
        style="
          width: 15px;
          height: 15px;
          border-radius: 50%;
          font-size: 14px;
          margin-right: 5px;
          z-index: 0;
        "
        [ngStyle]="{
          'background-image':
            'linear-gradient(45deg, var(--app-timber), var(--app-beachy))'
        }"
      ></div>
      <span style="font-size: 14px; z-index: 0">Gradient</span>
    </div>
  </button>
</mat-menu>

<mat-menu #stampOutlineMenu="matMenu" class="custom-menu">
  <button
    ngx-colors-trigger
    [colorPickerControls]="'no-alpha'"
    [colorsAnimation]="'popup'"
    [format]="'hex'"
    [formControl]="stampColorFormControl"
    class="menu-list-item"
    style="border-radius: 5px; position: relative"
    mat-menu-item
  >
    <div
      style="
        display: flex;
        justify-content: start;
        align-items: center;
        position: relative;
      "
    >
      <div
        style="
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100px;
          height: 40px;
        "
      ></div>
      <div
        style="
          width: 15px;
          height: 15px;
          border-radius: 50%;
          font-size: 14px;
          margin-right: 5px;
          background-color: var(--app-timber);
          z-index: 0;
        "
      ></div>
      <span style="font-size: 14px; z-index: 0">Select Colour</span>
    </div>
  </button>

  <button
    class="menu-list-item"
    style="border-radius: 5px"
    mat-menu-item
    (click)="stampColorFormControl.setValue(null)"
  >
    <span style="font-size: 14px; z-index: 0">No Colour</span>
  </button>
</mat-menu>

<mat-menu #stampFillMenu="matMenu" class="custom-menu">
  <button
    ngx-colors-trigger
    [colorPickerControls]="'no-alpha'"
    [colorsAnimation]="'popup'"
    [format]="'hex'"
    [formControl]="stampFillColorFormControl"
    class="menu-list-item"
    style="border-radius: 5px; position: relative"
    mat-menu-item
  >
    <div
      style="
        display: flex;
        justify-content: start;
        align-items: center;
        position: relative;
      "
    >
      <div
        style="
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100px;
          height: 40px;
        "
      ></div>
      <div
        style="
          width: 15px;
          height: 15px;
          border-radius: 50%;
          font-size: 14px;
          margin-right: 5px;
          background-color: var(--app-timber);
          z-index: 0;
        "
      ></div>
      <span style="font-size: 14px; z-index: 0">Select Colour</span>
    </div>
  </button>

  <button
    class="menu-list-item"
    style="border-radius: 5px"
    mat-menu-item
    (click)="stampFillColorFormControl.setValue(null)"
  >
    <span style="font-size: 14px; z-index: 0">No Colour</span>
  </button>
</mat-menu>

<mat-menu #stampFillEmptyMenu="matMenu" class="custom-menu">
  <button
    ngx-colors-trigger
    [colorPickerControls]="'no-alpha'"
    [colorsAnimation]="'popup'"
    [format]="'hex'"
    [formControl]="stampFillEmptyFormControl"
    class="menu-list-item"
    style="border-radius: 5px; position: relative"
    mat-menu-item
  >
    <div
      style="
        display: flex;
        justify-content: start;
        align-items: center;
        position: relative;
      "
    >
      <div
        style="
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100px;
          height: 40px;
        "
      ></div>
      <div
        style="
          width: 15px;
          height: 15px;
          border-radius: 50%;
          font-size: 14px;
          margin-right: 5px;
          background-color: var(--app-timber);
          z-index: 0;
        "
      ></div>
      <span style="font-size: 14px; z-index: 0">Select Colour</span>
    </div>
  </button>

  <button
    class="menu-list-item"
    style="border-radius: 5px"
    mat-menu-item
    (click)="stampFillEmptyFormControl.setValue(null)"
  >
    <span style="font-size: 14px; z-index: 0">No Colour</span>
  </button>
</mat-menu>
