<dialog-header
  [title]="editMode ? 'Update Configuration' : 'Add Configuration'"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  style="
    padding-bottom: 20px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <form [formGroup]="form" style="margin-top: 20px">
    <app-info-box
      title="Info"
      [body]="infoBody"
      [canDismiss]="false"
    ></app-info-box>

    <div class="form-field" style="margin-top: 20px">
      <single-select
        label="Type"
        [formCtrl]="typeFormControl"
        [value]="typeFormControl.value"
        [options]="types"
        (selected)="typeChanged()"
        property="name"
        [showIcon]="false"
      ></single-select>
    </div>

    <div
      class="form-field"
      style="margin-top: 20px"
      *ngIf="typeFormControl.value?.value === 'product'"
    >
      <div class="form-field-label">
        Product SKU<span
          ><mat-icon
            class="icon"
            [matTooltip]="skuTooltip"
            [matTooltipPosition]="'before'"
            style="cursor: help"
            #locationsTooltip="matTooltip"
            (click)="locationsTooltip.toggle()"
            >info_outlined</mat-icon
          ></span
        >
      </div>
      <input
        class="form-input"
        autocomplete="off"
        [formControl]="skuFormControl"
        maxlength="30"
        type="text"
      />
    </div>

    <div
      class="form-field"
      style="margin-top: 20px"
      *ngIf="typeFormControl.value?.value === 'order'"
    >
      <div class="form-field-label">
        Minimum Order value<span
          ><info-icon
            [text]="
              'The amount the customer must spend in order to qualify for a the specified number of stamps'
            "
          ></info-icon
        ></span>
      </div>
      <input
        class="form-input"
        autocomplete="off"
        placeholder="e.g. 9.99"
        [formControl]="valueFormControl"
        type="number"
        [maxlength]="7"
        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      />
    </div>

    <div
      class="form-field"
      style="margin-top: 20px"
      *ngIf="typeFormControl.value"
    >
      <single-select
        label="Loyalty Card"
        [formCtrl]="cardFormControl"
        [options]="cards"
        [value]="cardFormControl.value"
        property="title"
        [showIcon]="false"
        (selected)="stampsFormControl.reset()"
      ></single-select>
    </div>

    <div
      class="form-field"
      style="margin-top: 20px"
      *ngIf="cardFormControl.value"
    >
      <single-select
        infoText="The number of stamps to give to the customer for meeting the requirements of this configuration"
        label="Number of stamps"
        [formCtrl]="stampsFormControl"
        [options]="stampNumbers"
        [value]="stampsFormControl.value"
      >
      </single-select>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <app-secondary-button
    style="margin-right: 10px"
    [title]="'Cancel'"
    [disabled]="false"
    (tapped)="matDialogRef.close()"
  ></app-secondary-button>

  <app-primary-button
    [title]="'Save'"
    [disabled]="false"
    (tapped)="save()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>

<loader [loading]="loading"></loader>
