<page-header title="New Push Notification" [subtitle]="subtitle"></page-header>

<div class="action-container">
  <app-primary-button
    *ngIf="stepper.selectedIndex !== 0"
    [title]="'Save Draft'"
    [disabled]="loading"
    (tapped)="send(true)"
    [border]="'1px solid white'"
    [bgColor]="'var(--app-timber)'"
    [color]="'white'"
  ></app-primary-button>

  <div class="right-buttons" style="display: flex">
    <div style="margin-right: 10px">
      <app-primary-button
        [title]="stepper.selectedIndex === 0 ? 'Cancel' : 'Back'"
        [disabled]="loading"
        (tapped)="goBack(stepper)"
        border=" 1px solid var(--app-container)"
        bgColor="transparent"
        color="white"
      ></app-primary-button>
    </div>

    <app-primary-button
      border=" 1px solid var(--app-container)"
      [title]="
        stepper.selectedIndex === 2
          ? sendOption === 0
            ? 'Send'
            : 'Schedule'
          : 'Next'
      "
      [disabled]="nextDisabled(stepper)"
      (tapped)="goForward(stepper)"
      [tooltip]="getTooltip(stepper)"
      [icon]="
        stepper.selectedIndex === 2
          ? sendOption === 0
            ? 'send'
            : 'schedule'
          : undefined
      "
    ></app-primary-button>
  </div>
</div>
<mat-stepper
  linear
  labelPosition="bottom"
  #stepper
  [disableRipple]="true"
  [@.disabled]="true"
>
  <mat-step [completed]="true">
    <ng-template matStepLabel>Recipients</ng-template>
    <div style="margin-top: 20px" *ngIf="!loading">
      <div
        style="display: flex; justify-content: start; margin-left: 10px"
        *ngIf="!loading"
      >
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
          "
        >
          <app-search-input
            (onSubmit)="searchCustomers()"
            (onChanged)="searchTerm = $event"
            (onCancel)="searchTerm = ''; searchCustomers()"
            [backgroundColor]="'white'"
          ></app-search-input>
        </div>
        <app-primary-button
          [title]="constants.strings.filters"
          [disabled]="false"
          (tapped)="filter()"
          [bgColor]="'white'"
          border="1px solid var(--app-timber)"
          color="var(--app-timber)"
          [number]="hasFilters"
          [icon]="'filter_alt'"
        ></app-primary-button>
        <div style="margin-left: 10px">
          <app-secondary-button
            *ngIf="hasFilters > 0"
            [title]="constants.strings.clear"
            [disabled]="false"
            (tapped)="clearFilters()"
          ></app-secondary-button>
        </div>
      </div>

      <ngx-datatable
        #myTable
        class="material"
        [headerHeight]="45"
        [rows]="customers"
        [scrollbarV]="false"
        [scrollbarH]="true"
        [footerHeight]="50"
        [columnMode]="'force'"
        [rowHeight]="48"
        [externalPaging]="true"
        [messages]="{ emptyMessage: emptyString }"
        [count]="customers.length"
        [offset]="pageIndex"
        (page)="pageEvent($event)"
        [limit]="pageSize"
        (scroll)="handleScroll()"
        (activate)="rowTappedNGX($event)"
      >
        <ngx-datatable-column
          [minWidth]="50"
          [maxWidth]="50"
          [draggable]="false"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-header-template let-row="row">
            <mat-checkbox
              *ngIf="customers?.length"
              class="example-margin"
              [disabled]="!customers.length"
              [checked]="allChecked"
              (change)="checkAll($event.checked)"
            ></mat-checkbox>
          </ng-template>

          <ng-template
            ngx-datatable-cell-template
            let-row="row"
            let-onCheckboxChangeFn="onCheckboxChangeFn"
          >
            <mat-checkbox
              (click)="$event.stopPropagation()"
              class="example-margin"
              [checked]="row.checked"
              (change)="checkSingle($event.checked, row._id)"
            ></mat-checkbox>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Customer since" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.createdAt | date : constants.shortDate }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Name" [resizeable]="true">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.user?.name }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Email" [resizeable]="true">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.user?.email ?? "Hidden" }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Gender" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.user?.preferences?.gender ?? "-" }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="DOB" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{
              row?.user?.dob
                ? (row?.user?.dob | date : constants.shortDate)
                : "-"
            }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Stamps" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.stamps ?? "-" }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Redemptions" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.redemptions ?? "-" }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Rewards" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.rewards ?? "-" }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="PNs" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row?.pushNotifications ?? "-" }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-offset="offset"
            let-isVisible="isVisible"
          >
            <div class="page-count">
              <span>{{
                customers.length | pluralize : "customer / " : "customers / "
              }}</span
              ><span>{{ selectedCustomers?.length + " selected" }}</span>
            </div>

            <datatable-pager
              [pagerLeftArrowIcon]="'datatable-icon-left'"
              [pagerRightArrowIcon]="'datatable-icon-right'"
              [pagerPreviousIcon]="'datatable-icon-prev'"
              [pagerNextIcon]="'datatable-icon-skip'"
              [page]="pageIndex + 1"
              [size]="pageSize"
              [count]="length"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="table.onFooterPage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </mat-step>

  <mat-step [completed]="canReview()">
    <ng-template matStepLabel>Compose</ng-template>

    <div class="detail-container" id="left" style="padding-top: 0px">
      <form [formGroup]="form" *ngIf="!loading">
        <div class="row">
          <div class="col-lg-7">
            <div class="form-field" style="margin-top: 20px">
              <single-select
                label="Send as"
                [formCtrl]="fromFormControl"
                [options]="fromOptions"
                property="name"
                [value]="fromFormControl.value"
              ></single-select>
            </div>

            <div class="form-field" style="margin-top: 20px">
              <div
                class="form-field-label"
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <div>
                  Message
                  <span>
                    <info-icon
                      [text]="
                        'A maximum of 176 characters will be displayed in the user\'s push notification, but the full message will be viewable inside the app'
                      "
                    ></info-icon
                  ></span>
                </div>
                <span style="font-size: 12px; font-weight: normal"
                  >{{ descriptionFormControl.value?.length || 0 }}/{{
                    contentMaxChar
                  }}</span
                >
              </div>

              <textarea
                class="form-input"
                autocomplete="off"
                [formControl]="descriptionFormControl"
                placeholder="Hi [customer_name]! Visit today for a 20% discount 😊"
                type="text"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                [maxlength]="contentMaxChar"
                [rows]="3"
                (click)="updateCursorPosition($event)"
                (keyup)="updateCursorPosition($event)"
                (select)="updateCursorPosition($event)"
              ></textarea>
            </div>

            <div class="row">
              <div style="display: flex; justify-content: flex-start">
                <div
                  class="emoji-button"
                  style="margin-right: 10px"
                  (click)="aiActive ? openAIDialog() : null"
                  [ngStyle]="{ cursor: aiActive ? 'pointer' : 'not-allowed' }"
                  [matTooltip]="
                    aiActive
                      ? ''
                      : 'Please upgrade your plan to use this feature'
                  "
                >
                  ✨ AI Generator
                </div>
                <div
                  class="emoji-button"
                  style="margin-right: 10px"
                  (click)="openEmojiPicker()"
                >
                  😊 Emoji
                </div>
                <div
                  class="emoji-button"
                  (click)="insertCustomerNameAtCursor()"
                >
                  + Customer Name
                </div>
              </div>
            </div>

            <div class="form-field-label" style="margin-top: 20px">
              Give a Reward
            </div>
            <div class="custom-slider">
              <mat-slide-toggle
                [disabled]="false"
                [checked]="giveReward"
                (change)="toggleReward()"
              >
              </mat-slide-toggle>
            </div>
            <div style="margin-top: 20px" *ngIf="giveReward">
              <choose-reward
                *ngIf="!rewards?.length"
                (clicked)="openRewardDialog()"
                [label]="'Choose Reward'"
                [icon]="'redeem'"
              ></choose-reward>
            </div>
            <div
              class="social-container"
              *ngFor="let item of rewards"
              style="
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
              "
            >
              <div style="display: flex; align-items: center">
                <div
                  class="form-field-label"
                  style="
                    margin-bottom: 0px !important;
                    color: black;
                    font-size: 16px;
                  "
                >
                  {{ item?.reward?.title }}
                </div>
              </div>

              <mat-icon
                style="color: red; cursor: pointer"
                (click)="removeReward(item)"
                >cancel
              </mat-icon>
            </div>
          </div>
          <div class="col-lg-5 align-items-center">
            <div
              class="form-field-label"
              style="margin-top: 10px; text-align: center"
            >
              Preview
            </div>

            <div class="push-notification-container">
              <div
                class="pn-preview-container"
                [ngStyle]="{
                  top: '' + windowWidth * containerOffsetFactor + '%'
                }"
              >
                <div style="display: flex; align-items: center">
                  <img
                    src="../../../../assets/remyIcon.png"
                    alt="Your App Logo"
                    class="pn-logo"
                  />
                  <div class="pn-content">
                    <p class="pn-title">{{ fromFormControl.value?.name }}</p>
                    <p class="pn-message">
                      {{ descriptionFormControl.value ?? " " | truncate : 178 }}
                    </p>
                  </div>
                </div>
              </div>

              <!-- <img src="../../../../assets/iphone-top.png" height="300px" /> -->
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Review</ng-template>
    <div class="detail-container" id="left">
      <div class="form-field-label-title">Message summary</div>
      <hr />

      <div class="form-field-label-subtitle">Number of recipients</div>
      <div class="form-field-label-title">
        {{ selectedCustomers?.length }}
      </div>

      <div class="form-field-label-subtitle" style="margin-top: 20px">
        Message
      </div>
      <div class="form-field-label-title">
        {{ descriptionFormControl.value }}
      </div>

      <div class="col-lg-6">
        <hr />
        <div
          class="form-field"
          style="
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div class="form-field-label">Send immediately</div>
          <mat-slide-toggle
            [disabled]="!canSchedule"
            [checked]="sendOption === 0"
            (change)="toggleSendBasis(0, $event.checked)"
          >
          </mat-slide-toggle>
        </div>

        <div
          class="form-field"
          style="
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div class="form-field-label">Send according to schedule</div>

          <mat-slide-toggle
            [disabled]="!canSchedule"
            [checked]="sendOption === 1"
            (change)="toggleSendBasis(1, $event.checked)"
            [matTooltip]="
              canSchedule ? '' : 'Please upgrade your plan to use this feature'
            "
          >
          </mat-slide-toggle>
        </div>

        <div
          *ngIf="sendOption === 1"
          style="padding-left: 20px; margin-top: 10px"
        >
          <div class="form-field">
            <div class="form-field-label">
              Date<span style="color: red">*</span>
            </div>

            <input
              id="datetime-local-field"
              class="form-input"
              autocomplete="off"
              [formControl]="dateFormControl"
              type="date"
              [min]="minDate"
              [ngStyle]="{
                border:
                  dateFormControl.invalid && submitted
                    ? '1px solid red'
                    : '1px solid var(--app-light-grey)'
              }"
            />
          </div>

          <div class="form-field" style="margin-top: 10px">
            <single-select
              label="Time"
              [formCtrl]="timeFormControl"
              [value]="timeFormControl.value"
              [options]="times"
              [showIcon]="false"
            ></single-select>
          </div>
        </div>
      </div>
    </div>
  </mat-step>
</mat-stepper>

<loader [loading]="loading || saving"></loader>
