import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { Location } from '../../../models/index';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocationService } from 'src/app/services';
import { Constants } from 'src/app/app.constants';

@Component({
  selector: 'give-stamps-dialog',
  templateUrl: './give-stamps-dialog.component.html',
  styleUrls: ['./give-stamps-dialog.component.css'],
})
export class GiveStampsDialogComponent implements OnInit {
  form!: FormGroup;
  stampsFormControl!: FormControl;
  pointsFormControl!: FormControl;
  locationFormControl!: FormControl;
  loading = false;
  locationsLoading = false;
  error = false;
  locations: Location[] = [];
  options: any[] = [];

  constructor(
    public matDialogRef: MatDialogRef<GiveStampsDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private locationService: LocationService,
    private constants: Constants
  ) {
    if (this.data.number) {
      for (var i = 0; i < this.data.number; i++) {
        this.options.push({ number: i + 1 });
      }
    } else {
      this.options = this.data.options;
    }
  }

  ngOnInit(): void {
    let initialValue;

    if (this.data.number) {
      initialValue = this.options[0];
    }

    this.stampsFormControl = new FormControl(
      {
        value: initialValue,
        disabled: false,
      },
      this.data.stamps ? [Validators.required] : []
    );

    this.pointsFormControl = new FormControl(
      {
        value: '',
        disabled: false,
      },
      this.data.stamps ? [] : [Validators.required]
    );

    this.locationFormControl = new FormControl(
      {
        value: '',
        disabled: false,
      },
      [Validators.required]
    );

    this.form = this.fb.group({
      stamps: this.stampsFormControl,
      points: this.pointsFormControl,
      location: this.locationFormControl,
    });

    this.getLocations();
  }

  getLocations(): void {
    this.locationsLoading = true;

    this.locationService
      .getLocations(1000, 0)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.locations = res.docs.filter(
              (el: Location) => el.active === true && el.approved === true
            );

            if (this.locations?.length < 2) {
              this.locationFormControl.clearValidators();
              this.locationFormControl.updateValueAndValidity();
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => {
        this.locationsLoading = false;
      });
  }

  primaryTapped() {
    if (this.form?.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const value = this.data.stamps
      ? this.stampsFormControl.value?.number
      : this.pointsFormControl.value;

    const dataToReturn = { value: value, changed: true, location: undefined };

    if (this.locations.length > 1) {
      dataToReturn.location = this.locationFormControl.value?._id;
    }

    if (!Number.isInteger(dataToReturn.value)) {
      this.constants.snack('Number must be a whole');
      return;
    }

    this.matDialogRef.close(dataToReturn);
  }
}
