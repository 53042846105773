<page-header
  [title]="constants.strings.reports"
  [subtitle]="subtitle"
></page-header>

<div class="col-lg-6">
  <div class="table-container">
    <ngx-datatable
      #myTable
      class="material"
      [messages]="{
        emptyMessage:
          '<a href=https://app.remyrewards.co.uk/location>+ Add your first Location</a>'
      }"
      [headerHeight]="45"
      [rows]="reports"
      [scrollbarV]="false"
      [scrollbarH]="true"
      [footerHeight]="0"
      [columnMode]="'force'"
      [rowHeight]="60"
      [externalPaging]="false"
      [count]="length"
      (activate)="rowTappedNGX($event)"
    >
      <ngx-datatable-column name="Report Title" [resizeable]="false">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span style="font-weight: 600">{{ row?.title }}</span>
          <br />
          <span style="color: var(--app-text-grey)">{{ row?.subtitle }}</span>
        </ng-template>
      </ngx-datatable-column>
      <!-- 
      <ngx-datatable-footer>
        <ng-template
          ngx-datatable-footer-template
          let-rowCount="rowCount"
          let-pageSize="pageSize"
          let-offset="offset"
          let-isVisible="isVisible"
        >
        </ng-template>
      </ngx-datatable-footer> -->
    </ngx-datatable>
  </div>
  <div
    style="
      margin-top: 10px;
      margin-left: 5px;
      color: whitesmoke;
      font-style: italic;
    "
  >
    Request a new report by emailing us at
    <span
      ><a
        href="mailto:hello@remyrewards.co.uk"
        target="_blank"
        style="color: whitesmoke"
        >{{ email }}</a
      ></span
    >
  </div>
</div>

<loader [loading]="loading" title="Generating Report..."></loader>
