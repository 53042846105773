import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.css'],
})
export class PrimaryButtonComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Input() number: number = 0;
  @Input() title: string = '';
  @Input() tooltip: string = '';
  @Input() bgColor: string = 'var(--app-container)';
  @Input() border: string = '0px solid white';
  @Input() color: string = 'black';
  @Input() icon?: string | undefined;
  @Input() image?: string | undefined;
  @Input() reverse?: boolean = false;
  @Output() tapped: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
