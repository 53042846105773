<dialog-header
  title="Select Reward"
  (onClose)="matDialogRef.close()"
></dialog-header>

<div
  mat-dialog-content
  style="
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: var(--app-container);
  "
>
  <form [formGroup]="form" style="margin-top: 20px">
    <div class="form-field" *ngIf="!data?.customer">
      <single-select
        label="Customer"
        inBrackets="email"
        [formCtrl]="customerFormControl"
        [value]="customerFormControl.value"
        [options]="customers"
        property="name"
        [showIcon]="false"
        [showSearch]="true"
      ></single-select>
    </div>

    <div class="form-field">
      <single-select
        label="Reward"
        [formCtrl]="rewardFormControl"
        [value]="rewardFormControl.value"
        [options]="rewards"
        [inBrackets]="'description'"
        property="title"
        [showIcon]="false"
        (click)="hasRewards()"
      ></single-select>
    </div>

    <div class="form-field">
      <single-select
        label="Quantity"
        [formCtrl]="numberFormControl"
        [value]="numberFormControl.value"
        property="number"
        [options]="quantityOptions"
        [showIcon]="false"
      ></single-select>
    </div>

    <div
      class="form-field"
      style="margin-top: 20px; display: flex; justify-content: space-between"
    >
      <div class="form-field-label">Notify customer via push notification</div>
      <mat-slide-toggle
        [disabled]="false"
        [checked]="notify"
        (change)="notify = !notify"
      >
      </mat-slide-toggle>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <app-secondary-button
    style="margin-right: 10px"
    [title]="'Cancel'"
    [disabled]="false"
    (tapped)="matDialogRef.close()"
  ></app-secondary-button>

  <app-primary-button
    [title]="'Next'"
    [disabled]="!merchantActive"
    [tooltip]="!merchantActive ? 'Merchant account inactive' : ''"
    (tapped)="primaryTapped()"
    bgColor="var(--app-timber)"
    color="white"
  ></app-primary-button>
</div>

<loader [loading]="rewardsLoading || customersLoading || saving"></loader>
