import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { User } from '../../../models/index';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { TwoOptionAlertComponent } from 'src/app/shared/components/two-option-alert/two-option-alert.component';

import {
  AuthService,
  MerchantService,
  RewardService,
} from '../../../services/index';
import { Customer, Reward } from '../../../models/index';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';

@Component({
  selector: 'give-reward-dialog',
  templateUrl: './give-reward-dialog.component.html',
  styleUrls: ['./give-reward-dialog.component.css'],
})
export class GiveRewardDialogComponent implements OnInit {
  form!: FormGroup;
  rewardFormControl!: FormControl;
  customerFormControl!: FormControl;
  numberFormControl!: FormControl;

  saving = false;
  customers: Customer[] = [];
  rewards: Reward[] = [];
  rewardsLoading = false;
  customersLoading = false;
  error = false;

  notify = false;

  merchantActive = true;

  get loading(): boolean {
    return this.customersLoading || this.rewardsLoading;
  }

  quantityOptions = Array.from({ length: 10 }, (_, index) => index + 1)
    .filter((el: number) => {
      return !this.rewards.some((ex: any) => ex?.stamp?.number === el);
    })
    .map((el: number) => ({ number: el }));

  constructor(
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<GiveRewardDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private rewardService: RewardService,
    private constants: Constants,
    private merchantService: MerchantService,
    private mixpanel: AnalyticsAbstract,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.merchantActive = this.authService.merchantObj?.active ?? true;

    this.rewardFormControl = new FormControl({ value: '', disabled: false }, [
      Validators.required,
    ]);
    this.customerFormControl = new FormControl(
      { value: '', disabled: false },
      []
    );
    this.numberFormControl = new FormControl({ value: '', disabled: false }, [
      Validators.required,
    ]);

    this.form = this.fb.group({
      reward: this.rewardFormControl,
      number: this.numberFormControl,
      customer: this.customerFormControl,
    });

    this.getRewards();

    if (!this.data?.customer) {
      this.getCustomers();
      this.customerFormControl.setValidators([Validators.required]);
    }
  }

  giveReward(): void {
    const quantity = this.numberFormControl.value.number;
    const reward = this.rewardFormControl.value;
    const customer =
      this.data?.customer?._id ?? this.customerFormControl?.value?._id;

    this.saving = true;
    this.merchantService
      .giveReward(customer, reward._id, quantity, this.notify)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.giveRewardConfirmation(quantity, reward.title);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  giveRewardConfirmation(quantity: number, reward: string): void {
    const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: 'Confirmed',
        body: `You have given ${
          this.customerName
        } ${quantity} <b>${reward}</b> ${
          quantity === 1 ? 'reward' : 'rewards'
        }, which will now show in their Rewards Wallet`,
        buttonTwo: 'OK',
      },
      autoFocus: false,
      width: '300px',
      disableClose: true,
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe(() => window.location.reload());
  }

  get customerName(): string {
    return (
      this.data?.customer?.user?.name ?? this.customerFormControl?.value?.name
    );
  }

  getCustomers(): void {
    this.customersLoading = true;
    this.merchantService
      .getCustomers('_id', 'desc', 1000, 0, '', {})
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.customers = res.docs;

            this.customers.forEach((el) => {
              if (typeof el.user !== 'string') {
                el.name = el.user!.name;
                el.email = el.user!.email;
              }
            });

            this.customers.sort((a, b) => a.name!.localeCompare(b.name!));
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.customersLoading = false));
  }

  getRewards(): void {
    this.rewardsLoading = true;
    this.rewardService
      .getRewards(1000, 0)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.rewards = res.docs;
            this.rewards?.sort((a, b) => a.title!.localeCompare(b.title!));
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.rewardsLoading = false));
  }

  primaryTapped() {
    if (this.form?.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const quantity = this.numberFormControl.value.number;
    const reward = this.rewardFormControl.value;

    const dialogRef = this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: 'Confirm',
        body: `Do you want to give ${this.customerName} ${quantity} <b>${
          reward.title
        }</b> ${quantity === 1 ? 'reward' : 'rewards'}? <br><br>${
          quantity === 1 ? 'This' : 'These'
        } will show in their rewards wallet and they <b>will</b> ${
          this.notify ? '' : '<b>not</b>'
        } be notified`,
        buttonOne: 'Cancel',
        buttonTwo: 'Yes',
      },
      autoFocus: false,
      width: '300px',
      disableClose: true,
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((option) => {
      if (option == 1) {
        this.giveReward();
        this.mixpanel.track(Constants.analytics_keys.giveReward, {
          Customer: this.customerName,
          Reward: reward.title,
          Quantity: quantity,
        });
      }
    });
  }

  hasRewards(): void {
    if (!this.rewards.length) {
      this.constants.snack('Please add a reward first');
    }
  }
}
