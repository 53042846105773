import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { Constants } from 'src/app/app.constants';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TwoOptionAlertComponent } from 'src/app/shared/components/two-option-alert/two-option-alert.component';
import { ConfigurationDialogComponent } from 'src/app/shared/components/configuration-dialog/configuration-dialog.component';
import {
  IntegrationConfig,
  IntegrationConfiguration,
} from 'src/app/models/integrationConfig';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ShopifyDataDialogComponent } from 'src/app/shared/components/shopify-data-dialog/shopify-data-dialog.component';

import { AuthService, MerchantService } from '../../../services/index';
import { InviteDialogComponent } from 'src/app/shared/components/invite-dialog/invite-dialog.component';

@Component({
  selector: 'integrations',
  templateUrl: 'integrations.component.html',
  styleUrls: ['./integrations.component.css'],
})
export class IntegrationsComponent implements OnInit {
  form!: FormGroup;

  zapierAPIKeyFormControl!: FormControl;

  webhookFormControl!: FormControl;
  webhookEposNowFormControl!: FormControl;

  shopifySigningKey!: FormControl;
  storekitSigningKey!: FormControl;

  sumupUsername!: FormControl;
  sumupPassword!: FormControl;
  sumupSigningKey!: FormControl;
  sumupSubdomain!: FormControl;

  squareSigningKey!: FormControl;
  squareAccessToken!: FormControl;
  eposNowAccessToken!: FormControl;

  addSquareCustomers = false;
  addSumupCustomers = false;
  addEposNowCustomers = false;

  inviteUsersShopify = false;
  inviteUsersSquare = false;
  inviteUsersSumup = false;
  inviteUsersZapier = false;
  inviteUsersEposNow = false;
  inviteUsersStorekit = false;
  inviteUsersSquarespace = false;

  title = '';
  subtitle: string = '';

  integrations = {
    squarespace: 'squarespace',
    shopify: 'shopify',
    square: 'square',
    sumup: 'sumup',
    eposnow: 'eposnow',
    storekit: 'storekit',
    zapier: 'zapier',
  };

  tabs = [
    this.integrations.squarespace,
    this.integrations.shopify,
    this.integrations.square,
    this.integrations.sumup,
    this.integrations.eposnow,
    this.integrations.storekit,
    this.integrations.zapier,
  ];

  selectedTab = this.integrations.squarespace;
  selectedIndex = 0;

  saving = false;
  loading = false;
  error = false;

  zapierConfig?: IntegrationConfig;
  shopifyConfig?: IntegrationConfig;
  storekitConfig?: IntegrationConfig;
  squareConfig?: IntegrationConfig;
  sumupConfig?: IntegrationConfig;
  eposnowConfig?: IntegrationConfig;
  squarespaceConfig?: IntegrationConfig;

  hasShopify = false;
  hasStorekit = false;
  hasSumup = false;
  hasEposNow = false;
  hasSquare = false;
  hasSquarespace = false;
  hasZapier?: {
    enabled: boolean;
    apiKey: string;
  };

  merchantActive = false;

  constructor(
    public constants: Constants,
    private router: Router,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private authService: AuthService,
    private merchantService: MerchantService
  ) {
    this.hasShopify =
      this.authService.getNestedUserProperty(
        'merchant',
        this.integrations.shopify
      ) ?? false;

    this.hasStorekit =
      this.authService.getNestedUserProperty(
        'merchant',
        this.integrations.storekit
      ) ?? false;

    this.hasSumup =
      this.authService.getNestedUserProperty(
        'merchant',
        this.integrations.sumup
      ) ?? false;

    this.hasSquare =
      this.authService.getNestedUserProperty(
        'merchant',
        this.integrations.square
      ) ?? false;

    this.hasEposNow =
      this.authService.getNestedUserProperty(
        'merchant',
        this.integrations.eposnow
      ) ?? false;

    this.hasZapier =
      this.authService.getNestedUserProperty(
        'merchant',
        this.integrations.zapier
      ) ?? false;

    this.hasSquarespace =
      this.authService.getNestedUserProperty(
        'merchant',
        this.integrations.squarespace
      ) ?? false;

    this.route.queryParams.subscribe((params) => {
      const tab = params['tab'];
      if (tab) {
        this.selectedTab = tab;
        this.selectedIndex = this.tabs.findIndex((el) => el === tab);
      }
    });

    this.zapierAPIKeyFormControl = new FormControl({
      value: '',
      disabled: true,
    });

    const nanoid = this.authService.getNestedUserProperty('merchant', 'nanoid');
    const webhookUrl = `https://api.remyrewards.co.uk/v1/webhooks/orders/create/${nanoid}`;
    const webhookUrlEposNow = `/v1/webhooks/orders/create/${nanoid}`;

    this.webhookFormControl = new FormControl({
      value: webhookUrl,
      disabled: true,
    });

    this.webhookEposNowFormControl = new FormControl({
      value: webhookUrlEposNow,
      disabled: true,
    });

    this.shopifySigningKey = new FormControl({ value: '', disabled: false });

    this.storekitSigningKey = new FormControl({ value: '', disabled: false });

    this.sumupSigningKey = new FormControl({ value: '', disabled: false });
    this.sumupUsername = new FormControl({ value: '', disabled: false });
    this.sumupPassword = new FormControl({ value: '', disabled: false });
    this.sumupSubdomain = new FormControl({ value: '', disabled: false });

    this.squareSigningKey = new FormControl({ value: '', disabled: false });

    this.squareAccessToken = new FormControl({ value: '', disabled: false });

    this.eposNowAccessToken = new FormControl({ value: '', disabled: false });

    this.form = this.formBuilder.group({
      zapierApiKey: this.zapierAPIKeyFormControl,
      webhookUrl: this.webhookFormControl,
      webhookEposNowUrl: this.webhookEposNowFormControl,
      shopifySigningKey: this.shopifySigningKey,
      storekitSigningKey: this.storekitSigningKey,
      sumupSigningKey: this.sumupSigningKey,
      sumupSubdomain: this.sumupSubdomain,
      sumupUsername: this.sumupUsername,
      sumupPassword: this.sumupPassword,
      squareSigningKey: this.squareSigningKey,
      squareAccessToken: this.squareAccessToken,
      eposNowAuthToken: this.eposNowAccessToken,
    });
  }

  ngOnInit() {
    this.merchantActive = this.authService.merchantObj?.active ?? true;

    this.subtitle = this.authService.getNestedUserProperty('merchant', 'name');
    this.loadData();
  }

  copyApiKey(): void {
    const copyHandler = (e: ClipboardEvent) => {
      e.clipboardData!.setData(
        'text/plain',
        this.zapierAPIKeyFormControl.value
      );
      e.preventDefault();
      document.removeEventListener('copy', copyHandler);
    };

    document.addEventListener('copy', copyHandler);

    navigator.clipboard
      .writeText(this.zapierAPIKeyFormControl.value)
      .then(() => {
        this.constants.snack('Zapier API key copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy text to clipboard:', err);
      });
  }

  copyWebhookUrl(): void {
    const copyHandler = (e: ClipboardEvent) => {
      e.clipboardData!.setData('text/plain', this.webhookFormControl.value);
      e.preventDefault();
      document.removeEventListener('copy', copyHandler);
    };

    document.addEventListener('copy', copyHandler);

    navigator.clipboard
      .writeText(this.webhookFormControl.value)
      .then(() => {
        this.constants.snack('Webhook URL copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy text to clipboard:', err);
      });
  }

  tabChanged(index: any): void {
    let tab = this.tabs[index];

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false,
    });

    this.selectedTab = tab;

    this.loadData();
  }

  loadData(): void {
    const clearValidatorsFor = (controls: AbstractControl[]) => {
      controls.forEach((control) => control.clearValidators());
    };

    const setValidatorsFor = (
      controls: { control: AbstractControl; validators: ValidatorFn[] }[]
    ) => {
      controls.forEach(({ control, validators }) =>
        control.setValidators(validators)
      );
    };

    const updateValidityFor = (controls: AbstractControl[]) => {
      controls.forEach((control) => control.updateValueAndValidity());
    };

    const allControls = [
      this.shopifySigningKey,
      this.storekitSigningKey,
      this.squareSigningKey,
      this.squareAccessToken,
      this.sumupSigningKey,
      this.sumupSubdomain,
      this.sumupUsername,
      this.sumupPassword,
      this.eposNowAccessToken,
      this.zapierAPIKeyFormControl,
    ];

    const integrationsConfig = {
      [this.integrations.shopify]: {
        validators: [
          {
            control: this.shopifySigningKey,
            validators: [Validators.required],
          },
        ],
        toClear: [
          this.squareSigningKey,
          this.squareAccessToken,
          this.sumupSigningKey,
          this.sumupUsername,
          this.sumupPassword,
          this.sumupSubdomain,
          this.eposNowAccessToken,
          this.zapierAPIKeyFormControl,
          this.storekitSigningKey,
        ],
        action: () => this.getShopifyIntegration(),
      },
      [this.integrations.square]: {
        validators: [
          { control: this.squareSigningKey, validators: [Validators.required] },
          {
            control: this.squareAccessToken,
            validators: [Validators.required],
          },
        ],
        toClear: [
          this.shopifySigningKey,
          this.sumupSigningKey,
          this.sumupUsername,
          this.sumupPassword,
          this.sumupSubdomain,
          this.eposNowAccessToken,
          this.zapierAPIKeyFormControl,
          this.storekitSigningKey,
        ],
        action: () => this.getSquareIntegration(),
      },
      [this.integrations.sumup]: {
        validators: [
          { control: this.sumupSigningKey, validators: [Validators.required] },
          { control: this.sumupSubdomain, validators: [Validators.required] },
          { control: this.sumupUsername, validators: [Validators.required] },
          { control: this.sumupPassword, validators: [Validators.required] },
        ],
        toClear: [
          this.shopifySigningKey,
          this.squareSigningKey,
          this.squareAccessToken,
          this.eposNowAccessToken,
          this.zapierAPIKeyFormControl,
          this.storekitSigningKey,
        ],
        action: () => this.getSumupIntegration(),
      },
      [this.integrations.eposnow]: {
        validators: [
          {
            control: this.eposNowAccessToken,
            validators: [Validators.required],
          },
        ],
        toClear: [
          this.shopifySigningKey,
          this.squareSigningKey,
          this.squareAccessToken,
          this.zapierAPIKeyFormControl,
          this.sumupSigningKey,
          this.sumupSubdomain,
          this.sumupUsername,
          this.sumupPassword,
          this.storekitSigningKey,
        ],
        action: () => this.getEposNowIntegration(),
      },
      [this.integrations.storekit]: {
        validators: [
          {
            control: this.storekitSigningKey,
            validators: [Validators.required],
          },
        ],
        toClear: [
          this.shopifySigningKey,
          this.squareSigningKey,
          this.squareAccessToken,
          this.zapierAPIKeyFormControl,
          this.sumupSigningKey,
          this.sumupSubdomain,
          this.sumupUsername,
          this.sumupPassword,
          this.eposNowAccessToken,
        ],
        action: () => this.getStorekitIntegration(),
      },
      [this.integrations.squarespace]: {
        validators: [],
        toClear: [
          this.shopifySigningKey,
          this.squareSigningKey,
          this.squareAccessToken,
          this.zapierAPIKeyFormControl,
          this.sumupSigningKey,
          this.sumupSubdomain,
          this.sumupUsername,
          this.sumupPassword,
          this.storekitSigningKey,
        ],
        action: () => this.getSquarespaceIntegration(),
      },
      default: {
        validators: [
          {
            control: this.zapierAPIKeyFormControl,
            validators: [Validators.required],
          },
        ],
        toClear: [
          this.shopifySigningKey,
          this.squareSigningKey,
          this.squareAccessToken,
          this.sumupSigningKey,
          this.eposNowAccessToken,
          this.sumupSubdomain,
          this.sumupUsername,
          this.sumupPassword,
          this.storekitSigningKey,
        ],
        action: () => this.getZapierIntegration(),
      },
    };

    const selectedConfig =
      integrationsConfig[this.selectedTab] || integrationsConfig.default;

    selectedConfig.action();
    setValidatorsFor(selectedConfig.validators);
    clearValidatorsFor(selectedConfig.toClear);

    updateValidityFor(allControls);
  }

  showInfoDialog(title: string, body: string): void {
    this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: title,
        body: body,
        buttonTwo: 'Close',
      },
      width: '600px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  viewExampleMail(): void {
    window.open(
      'https://remy-system.s3.eu-west-2.amazonaws.com/Invite+email+example.png',
      '_blank'
    );
  }

  get overviewText(): string {
    return `Use our <b>${this.proper(
      this.selectedTab
    )}</b> integration to automatically give
        stamps to your customers for purchases in your online
        store or in-store EPos. <b>Customers must be pre-registered</b> on Remy,
        have added your loyalty card(s) to their Remy wallet, and
        place orders using the email associated with their Remy
        account in order for the linking to work.
      `;
  }

  get overviewTextStorekit(): string {
    return `Use our <b>${this.proper(
      this.selectedTab
    )}</b> integration to automatically give
        stamps to your customers for purchases in your online
        store or in-store EPos. <b>Customers must be pre-registered</b> on Remy,
        have added your loyalty card(s) to their Remy wallet, and
        place orders using the email associated with their Remy
        account in order for the linking to work. <a
                          href="https://remy-system.s3.eu-west-2.amazonaws.com/Remy+x+Storekit.pdf" target="_blank"
                          >Overview Guide</a
                        >
      `;
  }

  get titleOne(): string {
    return 'Shopify Integration Setup';
  }

  get titleOneZapier(): string {
    return 'Zapier Integration Setup';
  }

  get titleOneSquare(): string {
    return 'Square Integration Setup';
  }

  get titleOneSumup(): string {
    return 'SumUp Integration Setup';
  }

  get titleOneEposnow(): string {
    return 'EposNow Integration Setup';
  }

  get titleOneStorekit(): string {
    return 'Storekit Integration Setup';
  }

  get titleTwo(): string {
    return 'Webhook Signing Key';
  }

  get titleTwoSquare(): string {
    return 'Webhook Signature Key';
  }

  get titleTwoSumup(): string {
    return 'Webhook Verification Token';
  }

  get titleThreeSumup(): string {
    return 'Webbook Subdoman';
  }

  get bodyOne(): string {
    return `You'll need to create a new Webhook in your Shopify store using this URL, following these instructions:<br><br>

    1. Navigate to https://admin.shopify.com/store<br>
    2. Open <b>Settings</b><br>
    3. Click on <b>Notifications</b> in the left sidebar<br>
    4. Open <b>Webhooks</b><br>
    5. Create a new Webhook<br>
    6. Select the <b>Order Payment</b> Event, and the <b>JSON</b> format<br>
    7. Paste your unique <b>Webhook URL</b> into the URL field<br>
    8. Select the <b>latest</b> Webhook API version<br>
    9. Your Webhook is now set up, and Remy will be notified when orders have been paid for
  `;
  }

  get bodyOneStorekit(): string {
    return `You'll need to create a new Webhook in your Storekit store using this URL, following these instructions:<br><br>

    1. Navigate to https://dashboard.storekit.com/settings/webhooks<br>
    2. If it's not enabled, then you'll need to ask the Storekit team to enable it<br>
    3. Click <b>+ Add Endpoint</b><br>
    4. Enter this  Webhook URL into the <b>Endpoint URL</b> field in Storekit<br>
    5. From the <b>Subscribe to events</b> section, select <b>order.created</b><br>
    6. Click <b>Create</b>
  `;
  }

  get bodyOneZapier(): string {
    return `Follow the below instructions to get started with the Zapier x Remy integration:<br><br>

    1. Navigate to https://zapier.com/apps<br>
    2. Add the <b>Remy Rewards</b> integration<br>
    3. Securely connect your Zap to Remy using your API key <br>
    4. Select the <b>Create Order</b> event<br>
    5. Ensure that the Customer Email, Order Number, Order Value and Order Source are populated from your chosen source (e.g. storekit, deliveroo or just eat order details)<br>
    6. Your Zapier integration is now set up, and Remy will be notified when orders have been placed from your chosen source<br>
    7. It's important to note that the customer must already have your Remy loyalty card using the same email address provided in the Zap<br>
    8. Refer to this Zapier x Remy integration guide for more information: https://remy-rewards.notion.site/Remy-x-Zapier-Integration-Guide-4daaafed390b4a6aa1595b92ab24ff02
  `;
  }

  get bodyOneSquare(): string {
    return `You'll need to create a new App and Webhook in your <b>Square</b> store using this URL, following these instructions:<br><br>

    1. Navigate to https://developer.squareup.com/apps<br>
    2. Create a new App with the name <b>Remy</b> and skip the next two steps<br>
    3. Open the newly created App, and open <b>Webhooks/Subscriptions</b> from the left sidebar<br>
    4. Ensure that the <b>Production</b> tab is selected (not Sandbox)<br>
    5. Select <b>Add subscription</b><br>
    6. Give your webhook the name <b>remy-webook</b><br>
    7. Paste your <b>Webhook URL</b> into the <b>URL</b> field<br>
    8. Select the <b>latest</b> API version<br>
    9. Select the <b>payment.created</b> Event only<br>
    10. Save your webhook<br>
    11. Click on the new webhook you just created, and copy the <b>Signature key</b>, you'll need this for the next step
  `;
  }

  get bodyOneSumup(): string {
    return `You'll need to create a new Webhook in your <b>SumUp</b> POS Back Office using this URL, following these instructions:<br><br>

    1. Navigate to https://pos.thegoodtill.com/app/integrations/webhook<br>
    2. Paste your <b>Webhook URL</b> into the <b>Webhook URL</b> field<br>
    3. Tick the <b>sale.completed</b> event<br>
    4. Tick the <b>Enable Wehook Integration</b> option at the bottom<br>
    5. Click <b>Save Settings</b><br>
    6. Copy the <b>Verification Token</b> located above the table, you'll need this for the next step<br><br>
    7. To allow Remy to fetch information about eligible orders you will need to setup a new Admin user, navigate to https://pos.thegoodtill.com/app/users/all<br>
    8. Click <b>+ Add new User</b> with the following details:<br>
    9. Name: <b>Remy</b><br>
    10. Email: <b>hello@remyrewards.co.uk</b><br>
    11. Set a username and password of your choosing, make a note of these as you'll need them for the later steps<br>
    13. Role: <b>Admin</b><br>
    14. Make sure the <b>Active</b> box is ticked, then click <b>Submit</b>
  `;
  }

  get bodyOneEposNow(): string {
    return `You'll need to create a new Webhook in your <b>EposNow</b> Back Office using this URL, following these instructions:<br><br>

    1. Navigate to https://www.eposnowhq.com/Pages/BackOffice/Integrations/WebHooks/WebHookList.aspx<br>
    2. Click on <b>ADD WEBHOOK TRIGGGER</b><br>
    3. Select the API device you created earlier, the one you called <b>Remy</b><br>
    4. Enter the Webhook Route Path into the <b>Route Path</b> field<br>
    5. Select the <b>Complete Transaction</b> event<br>
    6. Click <b>ADD</b> to save the webhook
  `;
  }

  get bodyTwo(): string {
    return `Locate your Shopify Webhook Signing Key in the Webhook section, as outlined in the previous step. It's identified by a lengthy character string next to the label <b>'Your webhooks will be signed with'</b> and paste it here`;
  }

  get bodyTwoStorekit(): string {
    return `Locate your Storekit Webhook Signing Key in the Order Created webhook overview from the previous step. It's identified by a lengthy masked string under the label <b>'Signing Secret'</b>, you'll need to click on the eye icon to reveal it - it begins with 'whsec', then paste it here `;
  }

  get bodyTwoSquare(): string {
    return `Click on your newly created Square Webhook, and copy the <b>Signature key</b> and paste it here`;
  }

  get bodyTwoSumup(): string {
    return `From your Webhook Settings page which you accessed from the previous step, copy the <b>Verification Token</b> and paste it here`;
  }

  get bodyThreeSumup(): string {
    return `From your main Settings page, click on <b>Global Settings</b>, copy the <b>Subdomain</b> value`;
  }

  get accessTokenTitle(): string {
    return `Square Access Token`;
  }

  get accessTokenTitleEposnow(): string {
    return `Epos Now Authorization Token`;
  }

  get accessTokenBody(): string {
    return `Open the newley created app at https://developer.squareup.com/apps, then follow these instructions:<br><br>

    1. Click on the <b>Credentials</b> tab in the left sidebar<br>
    2. Ensure that the <b>Production</b> segment is selected<br>
    3. Copy the <b>Production Access Token</b><br><br>
    <i><b>Important</b>: When copying the Access Token, make sure to copy the un-masked version of this, and not just the bullet points you see (••••)</i>
  `;
  }

  get accessTokenBodyEposnow(): string {
    return `Open the API app in your EposNow back office interface at https://www.eposnowhq.com/Pages/BackOffice/Integrations/REST/RESTManagement.aspx, then follow these instructions:<br><br>

    1. Click on the <b>REST API</b> tab in the left sidebar<br>
    2. Create a new API Device, you can call this <b>Remy</b> and set the location to your main store<br>
    3. Paste the following URL into the <b>API URL</b> field: <b>https://api.remyrewards.co.uk</b><br>
    4. Click on the <b>Access Credentials</b> button<br>
    5. Copy the <b>Authorization Token</b> and paste it into this field
  `;
  }

  remove(type: string): void {
    const dialog = this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: `Remove ${this.proper(type)} Integration`,
        body: `Removing your ${this.proper(
          type
        )} integration will mean that purchases ${
          type === 'zapier'
            ? 'captured through your Zapier integration'
            : 'made in your ' + this.proper(type) + ' store'
        } will no longer by linked to your Remy customer loyalty cards<br><br>Do you want to continue with the de-integration?`,
        buttonOne: 'Cancel',
        buttonTwo: 'Continue',
      },
      width: '500px',
      autoFocus: false,
      disableClose: true,
      scrollStrategy: new NoopScrollStrategy(),
      panelClass: 'custom-dialog',
    });

    dialog.afterClosed().subscribe((option: boolean) => {
      if (option) {
        this.removeIntegration(type);
      }
    });
  }

  getShopifyIntegration(): void {
    this.loading = true;

    this.merchantService
      .getIntegration(this.integrations.shopify)
      .subscribe({
        next: (res?: IntegrationConfig) => {
          if (res) {
            this.shopifyConfig = res;
            this.shopifyConfig.configurations?.sort((a, b) =>
              (a?.type ?? 'order').localeCompare(b?.type ?? 'order')
            );

            this.shopifySigningKey.setValue(this.shopifyConfig?.signingKey);

            this.inviteUsersShopify =
              this.shopifyConfig.inviteUser?.active ?? false;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  getSquareIntegration(): void {
    this.loading = true;

    this.merchantService
      .getIntegration(this.integrations.square)
      .subscribe({
        next: (res?: IntegrationConfig) => {
          if (res) {
            this.squareConfig = res;
            this.squareConfig.configurations?.sort((a, b) =>
              (a?.type ?? 'order').localeCompare(b?.type ?? 'order')
            );

            this.squareSigningKey.setValue(this.squareConfig?.signingKey);
            this.squareAccessToken.setValue(this.squareConfig?.accessToken);

            this.addSquareCustomers = this.squareConfig.addCustomers ?? false;

            this.inviteUsersSquare =
              this.squareConfig.inviteUser?.active ?? false;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  getSumupIntegration(): void {
    this.loading = true;

    this.merchantService
      .getIntegration(this.integrations.sumup)
      .subscribe({
        next: (res?: IntegrationConfig) => {
          if (res) {
            this.sumupConfig = res;
            this.sumupConfig.configurations?.sort((a, b) =>
              (a?.type ?? 'order').localeCompare(b?.type ?? 'order')
            );

            this.sumupSigningKey.setValue(this.sumupConfig?.signingKey);
            this.sumupSubdomain.setValue(this.sumupConfig.subdomain);
            this.sumupUsername.setValue(this.sumupConfig?.username);
            this.sumupPassword.setValue(this.sumupConfig?.password);

            this.addSumupCustomers = this.sumupConfig.addCustomers ?? false;

            this.inviteUsersSumup =
              this.sumupConfig.inviteUser?.active ?? false;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  getEposNowIntegration(): void {
    this.loading = true;

    this.merchantService
      .getIntegration(this.integrations.eposnow)
      .subscribe({
        next: (res?: IntegrationConfig) => {
          if (res) {
            this.eposnowConfig = res;
            this.eposnowConfig.configurations?.sort((a, b) =>
              (a?.type ?? 'order').localeCompare(b?.type ?? 'order')
            );

            this.eposNowAccessToken.setValue(this.eposnowConfig?.accessToken);

            this.addEposNowCustomers = this.eposnowConfig.addCustomers ?? false;

            this.inviteUsersEposNow =
              this.eposnowConfig.inviteUser?.active ?? false;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  getStorekitIntegration(): void {
    this.loading = true;

    this.merchantService
      .getIntegration(this.integrations.storekit)
      .subscribe({
        next: (res?: IntegrationConfig) => {
          if (res) {
            this.storekitConfig = res;
            this.storekitConfig.configurations?.sort((a, b) =>
              (a?.type ?? 'order').localeCompare(b?.type ?? 'order')
            );

            this.storekitSigningKey.setValue(this.storekitConfig?.signingKey);

            this.inviteUsersStorekit =
              this.storekitConfig.inviteUser?.active ?? false;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  getZapierIntegration(): void {
    this.loading = true;

    this.merchantService
      .getIntegration(this.integrations.zapier)
      .subscribe({
        next: (res?: IntegrationConfig) => {
          if (res) {
            this.zapierConfig = res;
            this.zapierConfig.configurations?.sort((a, b) =>
              (a?.type ?? 'order').localeCompare(b?.type ?? 'order')
            );

            this.zapierAPIKeyFormControl.setValue(
              this.zapierConfig?.signingKey
            );

            this.inviteUsersZapier =
              this.zapierConfig.inviteUser?.active ?? false;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  getSquarespaceIntegration(): void {
    this.loading = true;

    this.merchantService
      .getIntegration(this.integrations.squarespace)
      .subscribe({
        next: (res?: IntegrationConfig) => {
          if (res) {
            this.squarespaceConfig = res;
            this.squarespaceConfig.configurations?.sort((a, b) =>
              (a?.type ?? 'order').localeCompare(b?.type ?? 'order')
            );

            this.inviteUsersSquarespace =
              this.squarespaceConfig.inviteUser?.active ?? false;
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  save(): void {
    if (this.form.invalid) {
      this.constants.snack('Please complete all required fields');

      // Loop through form controls to find invalid ones
      Object.keys(this.form.controls).forEach((key) => {
        const controlErrors = this.form.get(key)?.errors;
        if (controlErrors) {
          console.log(`Field: ${key}, Errors:`, controlErrors);
        }
      });

      return;
    }

    this.saving = true;

    if (this.selectedTab === this.integrations.shopify) {
      this.saveShopify();
    } else if (this.selectedTab === this.integrations.square) {
      this.saveSquare();
    } else if (this.selectedTab === this.integrations.sumup) {
      this.saveSumup();
    } else if (this.selectedTab === this.integrations.eposnow) {
      this.saveEposNow();
    } else if (this.selectedTab === this.integrations.storekit) {
      this.saveStorekit();
    } else if (this.selectedTab === this.integrations.squarespace) {
      this.saveSquarespace();
    } else {
      this.saveZapier();
    }
  }

  saveShopify(): void {
    const key = this.shopifySigningKey.value?.trim();

    this.merchantService
      .addShopify({
        key: key,
        inviteUsers: this.inviteUsersShopify,
      })
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.constants.snack('Shopify Integration Updated');

            if (this.shopifyConfig) {
              this.shopifyConfig.signingKey = key;
            }

            if (!this.shopifyConfig?.configurations?.length) {
              this.addConfigDialog();
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  saveSumup(): void {
    const key = this.sumupSigningKey.value?.trim();
    const subdomain = this.sumupSubdomain.value?.trim();
    const username = this.sumupUsername.value?.trim();
    const password = this.sumupPassword.value?.trim();

    this.merchantService
      .addSumup({
        key: key,
        subdomain: subdomain,
        username: username,
        password: password,
        addCustomers: this.addSumupCustomers,
        inviteUsers: this.inviteUsersSumup,
      })
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.constants.snack('SumUp Integration Updated');

            if (this.sumupConfig) {
              this.sumupConfig.signingKey = key;
              this.sumupConfig.subdomain = subdomain;
            }

            if (!this.sumupConfig?.configurations?.length) {
              this.addConfigDialog();
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  saveEposNow(): void {
    const token = this.eposNowAccessToken?.value?.trim();

    this.merchantService
      .addEposNow({
        token: token,
        addCustomers: this.addEposNowCustomers,
        inviteUsers: this.inviteUsersEposNow,
      })
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.constants.snack('EposNow Integration Updated');

            if (this.eposnowConfig) {
              this.eposnowConfig.accessToken = token;
            }

            if (!this.eposnowConfig?.configurations?.length) {
              this.addConfigDialog();
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  saveStorekit(): void {
    const key = this.storekitSigningKey?.value?.trim();

    this.merchantService
      .addStorekit({
        key: key,
        inviteUsers: this.inviteUsersStorekit,
      })
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.constants.snack('Storekit Integration Updated');

            if (this.storekitConfig) {
              this.storekitConfig.signingKey = key;
            }

            if (!this.storekitConfig?.configurations?.length) {
              this.addConfigDialog();
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  saveSquare(): void {
    const key = this.squareSigningKey.value?.trim();
    const token = this.squareAccessToken.value?.trim();

    this.merchantService
      .addSquare({
        key: key,
        token: token,
        addCustomers: this.addSquareCustomers,
        inviteUsers: this.inviteUsersSquare,
      })
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.constants.snack('Square Integration Updated');

            if (this.squareConfig) {
              this.squareConfig.signingKey = key;
              this.squareConfig.accessToken = token;
            }

            if (!this.squareConfig?.configurations?.length) {
              this.addConfigDialog();
            }
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  saveZapier(): void {
    this.merchantService
      .addZapier({
        inviteUsers: this.inviteUsersZapier,
      })
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.constants.snack('Zapier Integration Updated');
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  saveSquarespace(): void {
    this.merchantService
      .addSquarespace({
        inviteUsers: this.inviteUsersSquarespace,
      })
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.constants.snack('Squarespace Integration Updated');
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  addConfigDialog(): void {
    this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: 'Configurations',
        body: 'You need to add at least one configuration before your integration is complete, you can do this by clicking on "<b>+ Add a Configuration"</b>',

        buttonTwo: 'OK',
      },
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      width: '350px',
      disableClose: true,
      panelClass: 'custom-dialog',
    });
  }

  removeIntegration(type: string): void {
    this.saving = true;

    if (type === this.integrations.squarespace) {
      this.merchantService
        .disconnectSquarespace()
        .subscribe({
          next: (res: any) => {
            if (res) {
              this.constants.snack(`${this.proper(type)} integration removed`);

              this.squarespaceConfig = undefined;
              this.inviteUsersSquarespace = false;
            }
          },
          error: (res: HttpErrorResponse) => {
            this.constants.snack(res.error.message);
          },
        })
        .add(() => (this.saving = false));
    } else {
      this.merchantService
        .removeIntegration(type)
        .subscribe({
          next: (res: any) => {
            if (res) {
              this.constants.snack(`${this.proper(type)} integration removed`);

              if (type === this.integrations.shopify) {
                this.shopifyConfig = undefined;
                this.shopifySigningKey.setValue('');
                this.inviteUsersShopify = false;
              } else if (type === this.integrations.square) {
                this.squareConfig = undefined;
                this.squareSigningKey.setValue('');
                this.squareAccessToken.setValue('');
                this.inviteUsersSquare = false;
                this.addSquareCustomers = false;
              } else if (type === this.integrations.sumup) {
                this.sumupConfig = undefined;
                this.sumupSigningKey.setValue('');
                this.sumupSubdomain.setValue('');
                this.sumupUsername.setValue('');
                this.sumupPassword.setValue('');
                this.inviteUsersSumup = false;
                this.addSumupCustomers = false;
              } else if (type === this.integrations.eposnow) {
                this.eposnowConfig = undefined;
                this.eposNowAccessToken.setValue('');
                this.inviteUsersEposNow = false;
                this.addEposNowCustomers = false;
              } else {
                this.zapierConfig = undefined;
                this.zapierAPIKeyFormControl.setValue('');
                this.inviteUsersZapier = false;
              }
            }
          },
          error: (res: HttpErrorResponse) => {
            this.constants.snack(res.error.message);
          },
        })
        .add(() => (this.saving = false));
    }
  }

  addConfiguration(): void {
    var canAddOrderValue = false;

    let config =
      this.selectedTab === this.integrations.shopify
        ? this.shopifyConfig
        : this.selectedTab === this.integrations.square
        ? this.squareConfig
        : this.selectedTab === this.integrations.sumup
        ? this.sumupConfig
        : this.selectedTab === this.integrations.eposnow
        ? this.eposnowConfig
        : this.selectedTab === this.integrations.storekit
        ? this.storekitConfig
        : this.selectedTab === this.integrations.squarespace
        ? this.squarespaceConfig
        : this.zapierConfig;

    if (!config?.configurations?.find((el) => el.type === 'order')) {
      canAddOrderValue = true;
    }

    const dialog = this.dialog.open(ConfigurationDialogComponent, {
      width: '500px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom-dialog',
      data: {
        orderAvailable: canAddOrderValue,
        type: this.proper(this.selectedTab),
      },
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((data: any) => {
      if (data) {
        this.addNewConfiguration(data);
      }
    });
  }

  addNewConfiguration(data: IntegrationConfiguration): void {
    this.loading = true;

    this.merchantService
      .addIntegrationConfiguration(data, this.selectedTab)
      .subscribe({
        next: (res?: IntegrationConfig) => {
          if (res) {
            window.location.reload();
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  editConfiguration(data: IntegrationConfiguration): void {
    const dialog = this.dialog.open(ConfigurationDialogComponent, {
      width: '500px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
      data: {
        configuration: data,
        type: this.proper(this.selectedTab),
      },
    });

    dialog.afterClosed().subscribe((data: any) => {
      if (data) {
        this.updateConfiguration(data);
      }
    });
  }

  updateConfiguration(data: IntegrationConfiguration): void {
    this.loading = true;

    this.merchantService
      .updateIntegrationConfiguration(data, this.selectedTab)
      .subscribe({
        next: (res?: IntegrationConfig) => {
          if (res) {
            window.location.reload();
          }
        },
        error: (res: HttpErrorResponse) => {
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  deleteConfiguration(id: string): void {
    const dialog = this.dialog.open(TwoOptionAlertComponent, {
      data: {
        title: 'Remove Configuration',
        body: 'Do you want to remove this configuration?',
        buttonOne: 'Cancel',
        buttonTwo: 'Yes',
      },
      width: '400px',
      autoFocus: false,
      disableClose: true,
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((option: boolean) => {
      if (option) {
        this.loading = true;

        this.merchantService
          .deleteIntegrationConfiguration(id, this.selectedTab)
          .subscribe({
            next: (res?: IntegrationConfig) => {
              if (res) {
                let config;

                if (this.selectedTab === this.integrations.shopify) {
                  config = this.shopifyConfig;
                } else if (this.selectedTab === this.integrations.square) {
                  config = this.squareConfig;
                } else if (this.selectedTab === this.integrations.sumup) {
                  config = this.sumupConfig;
                } else if (this.selectedTab === this.integrations.eposnow) {
                  config = this.eposnowConfig;
                } else if (this.selectedTab === this.integrations.storekit) {
                  config = this.storekitConfig;
                } else if (this.selectedTab === this.integrations.squarespace) {
                  config = this.squarespaceConfig;
                } else {
                  config = this.zapierConfig;
                }

                if (config) {
                  config.configurations = config?.configurations?.filter(
                    (el) => el._id !== id
                  );
                }
              }
            },
            error: (res: HttpErrorResponse) => {
              this.constants.snack(res.error.message);
            },
          })
          .add(() => (this.loading = false));
      }
    });
  }

  viewData(): void {
    this.dialog.open(ShopifyDataDialogComponent, {
      data: {
        title:
          this.selectedTab === this.integrations.shopify
            ? 'Shopify Orders (Qualifying)'
            : this.selectedTab == this.integrations.square
            ? 'Square Orders (Qualifying)'
            : this.selectedTab === this.integrations.sumup
            ? 'SumUp Orders (Qualifying)'
            : this.selectedTab === this.integrations.eposnow
            ? 'EposNow Orders (Qualifying)'
            : this.selectedTab === this.integrations.storekit
            ? 'Storekit Orders (Qualifying)'
            : this.selectedTab === this.integrations.squarespace
            ? 'Squarespace Orders (Qualifying)'
            : 'Zapier Orders (Qualifying)',
        type: this.selectedTab,
      },
      autoFocus: false,
      width: '1200px',
      minHeight: '600px',
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  viewInvites(): void {
    this.dialog.open(InviteDialogComponent, {
      data: {
        title: 'Invites sent to customers',
        type: this.selectedTab,
      },
      autoFocus: false,
      width: '1200px',
      minHeight: '600px',
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  get infoIconTooltip(): string {
    return 'Customers may qualify for multiple stamps through various value and product configurations. Use this setting to limit customers to a single stamp per order, regardless of the number of qualifying products';
  }

  get addCustomersTooltip(): string {
    return 'Enable this for customers to be automatically added to your Epos customer list when they take one of your Remy loyalty cards for the first time, their email and name will be taken';
  }

  get inviteUsersTooltip(): string {
    return 'Enable this feature to immediately email customers without a Remy account or loyalty card, inviting them to join - they will only be emailed once';
  }

  proper(str: string) {
    return str[0].toUpperCase() + str.substring(1);
  }

  rowLabel(item: IntegrationConfiguration) {
    let stampString = item.numberOfStamps === 1 ? 'stamp' : 'stamps';

    if (item.type === 'order') {
      return `£${item?.value} (${item.numberOfStamps} ${stampString})`;
    } else {
      return `${'SKU ' + item?.sku} (${item.numberOfStamps} ${stampString})`;
    }
  }

  connectToSquarespace(): void {
    ///IMPROVE THIS FOR SECURITY

    const clientId = 'tLCwUfu7gssR6Wg1bnOKgE4j5h6Q1iEV';
    const redirectUri = 'https://app.remyrewards.co.uk/oauth';
    const scope = 'website.orders.read';
    const state = 'dsfsdfsd454535';
    const accessType = 'offline';

    window.location.href = `https://login.squarespace.com/api/1/login/oauth/provider/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}&access_type=${accessType}`;
  }
}
