import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Constants } from 'src/app/app.constants';
import { AnalyticsAbstract } from 'src/app/services/analytics/analytics.abstract';
import { RewardDialogComponent } from '../../card/reward-dialog/reward-dialog.component';
import { EmojiDialogComponent } from '../emoji-dialog/emoji-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BirthdayClubInfoComponent } from '../birthday-club-info/birthday-club-info.component';
import { MerchantService, AuthService } from '../../../services/index';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'automations_dialog',
  templateUrl: './automations_dialog.component.html',
  styleUrls: ['./automations_dialog.component.css'],
})
export class AutomationsDialogComponent implements OnInit {
  error = false;
  saving = false;
  loading = false;

  tabs = ['birthday'];
  selectedIndex = 0;
  selectedTab = 'birthday';

  cursorPosition = 0;

  birthdayClubEnabled = false;
  birthdayForm!: FormGroup;
  birthdayPnMessageFormControl: FormControl = new FormControl();
  birthdayIncludeReward = false;
  birthdayRewards: any[] = [];

  hasBirthdayClubAccess = false;

  contentMaxChar = 180;
  canEngage = false;
  whenToSendFormControl!: FormControl;

  options: any[] = [
    { name: 'Day of birthday', value: 0 },
    { name: '1 day before', value: 1 },
    { name: '2 days before', value: 2 },
    { name: '3 days before', value: 3 },
    { name: '4 days before', value: 4 },
    { name: '5 days before', value: 5 },
    { name: '6 days before', value: 6 },
    { name: '7 days before', value: 7 },
  ];

  merchantActive = false;

  constructor(
    public matDialogRef: MatDialogRef<AutomationsDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mixpanel: AnalyticsAbstract,
    public dialog: MatDialog,
    private constants: Constants,
    private merchantService: MerchantService,
    private router: Router,
    private authService: AuthService,
    private ps: PermissionsService
  ) {
    this.canEngage = this.ps.permissions.engage.view();
  }

  ngOnInit(): void {
    this.merchantActive = this.authService.merchantObj?.active ?? true;

    this.getAutomations();

    this.birthdayPnMessageFormControl = new FormControl(
      { value: '', disabled: false },
      Validators.required
    );

    this.whenToSendFormControl = new FormControl(
      { value: this.options[0], disabled: false },
      Validators.required
    );

    this.birthdayForm = this.fb.group({
      birthdayPnMessage: this.birthdayPnMessageFormControl,
    });
  }

  getAutomations(): void {
    this.loading = true;
    this.merchantService
      .getAutomations()
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.hasBirthdayClubAccess = res.hasAccess;
            const birthdayClub = res?.automations?.birthdayClub;

            this.birthdayClubEnabled = birthdayClub?.enabled || false;

            if (birthdayClub?.message) {
              this.birthdayPnMessageFormControl.setValue(birthdayClub?.message);
            }

            if (birthdayClub?.reward) {
              this.birthdayIncludeReward = true;
              this.birthdayRewards = [{ reward: birthdayClub?.reward }];
            }

            const whenToSendOption = this.options.find(
              (el) => el.value === birthdayClub?.daysBeforeBirthday || 0
            );
            this.whenToSendFormControl.setValue(whenToSendOption);
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.loading = false));
  }

  save(): void {
    if (!this.birthdayForm.valid && this.birthdayClubEnabled) {
      this.constants.snack('Please complete all required fields');
      return;
    }

    if (this.birthdayIncludeReward && !this.birthdayRewards?.length) {
      this.constants.snack('Please include at least one reward');
      return;
    }

    let data = {
      birthdayEnabled: this.birthdayClubEnabled,
      birthdayMessage: undefined,
      birthdayReward: undefined,
      daysBeforeBirthday: this.whenToSendFormControl.value?.value || 0,
    };

    if (this.birthdayClubEnabled) {
      data.birthdayMessage = this.birthdayPnMessageFormControl.value?.trim();
    }

    if (this.birthdayIncludeReward && this.birthdayRewards?.length) {
      data.birthdayReward = this.birthdayRewards[0]?.reward?._id;
    }

    this.updateBirthdayClub(data);
  }

  updateBirthdayClub(data: any): void {
    this.mixpanel.track(Constants.analytics_keys.updateBirthdayClub, {
      Enabled: data.birthdayEnabled,
    });

    this.saving = true;
    this.merchantService
      .updateBirthdayClub(
        data.daysBeforeBirthday,
        data.birthdayEnabled,
        data.birthdayMessage,
        data.birthdayReward
      )
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.constants.snack('Automation saved');
            this.matDialogRef.close();
          }
        },
        error: (res: HttpErrorResponse) => {
          this.error = true;
          this.constants.snack(res.error.message);
        },
      })
      .add(() => (this.saving = false));
  }

  tabChanged(index: any): void {
    let tab = this.tabs[index];

    this.selectedTab = tab;

    this.mixpanel.track(Constants.analytics_keys.selectDashboardTab, {
      Tab: tab,
    });
  }

  openRewardDialog(): void {
    const dialogRef = this.dialog.open(RewardDialogComponent, {
      data: {
        rewardOnly: true,
        exclusions: this.birthdayRewards,
      },
      width: '450px',
      autoFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.birthdayRewards.push(data);
      }
    });
  }

  removeReward(item: any): void {
    const index = this.birthdayRewards.findIndex((el) => el === item);
    if (index !== -1) {
      this.birthdayRewards.splice(index, 1);
    }
  }

  openEmojiPicker(): void {
    this.mixpanel.track(Constants.analytics_keys.openEmojiPicker);

    const dialogRef = this.dialog.open(EmojiDialogComponent, {
      autoFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data?.emoji) {
        this.insertEmojiAtCursor(data.emoji);
      }
    });
  }

  insertEmojiAtCursor(emoji: string) {
    const currentValue = this.birthdayPnMessageFormControl.value;
    const newValue =
      currentValue.slice(0, this.cursorPosition) +
      emoji +
      currentValue.slice(this.cursorPosition);
    this.birthdayPnMessageFormControl.setValue(newValue);
  }

  updateCursorPosition(event: any) {
    this.cursorPosition = event.target.selectionStart;
  }

  toggleReward(): void {
    this.birthdayIncludeReward = !this.birthdayIncludeReward;

    if (!this.birthdayIncludeReward) {
      this.birthdayRewards = [];
    }
  }

  seePlans(): void {
    this.matDialogRef.close();
    this.router.navigate([Constants.routes.plans]);
  }

  get infoBoxBody() {
    return `Enable Birthday Club to schedule automated push notifications and rewards to be sent to your customers on or before their birthday. <i>Push notifications will be sent at 9am GMT/BST on the day selected</i>`;
  }

  get title(): string {
    return this.canEngage
      ? 'Birthday Club is only available on Growth plans'
      : 'Birthday Club can only be managed by the Merchant Admin';
  }

  get subtitle(): string {
    return this.canEngage
      ? `Upgrade now to benefit from this automation to schedule automated
            push notifications and rewards to be sent to your customers on their
            birthday`
      : `Please contact the merchant administrator to set up Birthday Club`;
  }

  openBirthdayInfoDialog(): void {
    this.dialog.open(BirthdayClubInfoComponent, {
      data: {},
      maxWidth: '600px',
      autoFocus: false,
      panelClass: 'custom-dialog',
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  insertCustomerNameAtCursor(): void {
    const currentValue = this.birthdayPnMessageFormControl.value;
    const newValue =
      (currentValue ?? '').slice(0, this.cursorPosition) +
      '[customer_name]' +
      (currentValue ?? '').slice(this.cursorPosition);
    this.birthdayPnMessageFormControl.setValue(newValue);
  }
}
